import React, { useEffect, useState, useContext, useRef } from "react";
import { navBarContext, authContext } from "./context/context";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  addDoc,
  getDoc,
  updateDoc,
  doc,
} from "../firebase/firebaseApp";
import {
  Loading,
  PrairiButton,
  UserTracking,
  Modal,
  MatchDetail,
  TextButton,
} from "@peterfosso/prairi-components";
import axios from "axios";
import { orderBy } from "firebase/firestore";

const Users = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const [completedUsers, setCompletedUsers] = useState([])
  const [incompleteUsers, setIncompleteUsers] = useState([])
  const [showComplete, setShowComplete] = useState(true);
  const [showIncomplete, setShowIncomplete] = useState(false);
  const [preUsers, setPreUsers] = useState([]);
  const [completedUsersCount, setCompletedUsersCount] = useState(0);
  const [formUsersCount, setFormUsersCount] = useState(0);
  const [registeredUsersCount, setRegisteredUsersCount] = useState(0);
  const [invitedUsersCount, setInvitedUsersCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [archivedMatches, setArchivedMatches] = useState([]);
  const [interestedMatches, setInterestedMatches] = useState([]);
  const [pendingMatches, setPendingMatches] = useState([]);
  const [notInterestedMatches, setNotInterestedMatches] = useState([]);
  const [archivedPopup, setArchivedPopup] = useState(null);
  const [pendingPopup, setPendingPopup] = useState(null);
  const [interestedPopup, setInterestedPopup] = useState(null);
  const [notInterestedPopup, setNotInterestedPopup] = useState(null);
  const [archivedMatchesLoaded, setArchivedMatchesLoaded] = useState(false);
  const [interestedMatchesLoaded, setInterestedMatchesLoaded] = useState(false);
  const [pendingMatchesLoaded, setPendingMatchesLoaded] = useState(false);
  const [notInterestedMatchesLoaded, setNotInterestedMatchesLoaded] =
    useState(false);
  const { uid } = currentUser || {};

  const isAdminUser =
    currentUser && currentUser.uid === "A3Go76mJaLT2GeqHsxlYZRCOWur2";

  let [users, setUsers] = useState([]);

  const handleShowComplete = () => {
    setShowComplete(true)
    setShowIncomplete(false)
  }

  const handleShowInomplete = () => {
    setShowIncomplete(true)
    setShowComplete(false)
  }

  const fetchUsers = async () => {
    const usersCollection = collection(db, "users");
    const snapshot = await getDocs(usersCollection, { fromCache: false });
    let userData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    const usersWithLatest = userData.filter((user) => user.latest);
    const usersWithoutLatest = userData.filter((user) => !user.latest);
    usersWithLatest.sort((a, b) => {
        const dateA = new Date(
            a.latest[0].timestamp.seconds * 1000 +
            a.latest[0].timestamp.nanoseconds / 1000000
        );
        const dateB = new Date(
            b.latest[0].timestamp.seconds * 1000 +
            b.latest[0].timestamp.nanoseconds / 1000000
        );
        return dateB - dateA; // Compare the dates
    });

    // Concatenate the sorted arrays
    userData = [...usersWithLatest, ...usersWithoutLatest];

    const completedUsersData = userData.filter((user) => user.formData?.matching?.completed === true);
    const incompleteUsersData = userData.filter((user) => !user.formData?.matching?.completed);

    const pendingMatchesSnapshot = await getDocs(
        collection(db, "pendingMatches")
    );
    completedUsersData.forEach((user) => {
        const userId = user.id;
        const count = pendingMatchesSnapshot.docs.filter((doc) => {
            const data = doc.data();
            return data.investor === userId || data.startup === userId;
        }).length;
        user.pendingMatchesCount = count;
    });

    const totalMatchesSnapshot = await getDocs(collection(db, "matches"));
    completedUsersData.forEach((user) => {
        const userId = user.id;
        const count = totalMatchesSnapshot.docs.filter((doc) => {
            const data = doc.data();
            return data.startup === userId;
        }).length;
        user.totalMatches = count;
    });

    const formUsers = userData.filter((user) => user.formFirstView);
    const invitedUsers = userData.filter(
        (user) => user.invited === true && !user.registered
    );

    setFormUsersCount(formUsers.length);
    setCompletedUsersCount(completedUsersData.length);
    setRegisteredUsersCount(incompleteUsersData.length);
    setInvitedUsersCount(invitedUsers.length);
    setCompletedUsers(completedUsersData);
    setIncompleteUsers(incompleteUsersData);
    console.log("Users fetched");
};

const fetchPreUsers = async () => {
  const preUsersCollection = collection(db, "preUsers");
  const snapshot = await getDocs(preUsersCollection, { fromCache: false });
  let preUserData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  const filteredPreUsers = preUserData.filter((user) => user.pre === true);
  console.log("pre users fetched")
  const usersWithLatest = preUserData.filter((user) => user.latest);
  const usersWithoutLatest = preUserData.filter((user) => !user.latest);
  // const usersWithLatest = filteredPreUsers.filter((user) => user.latest);
  // const usersWithoutLatest = filteredPreUsers.filter((user) => !user.latest);
  usersWithLatest.sort((a, b) => {
      const dateA = new Date(
          a.latest[0].timestamp.seconds * 1000 +
          a.latest[0].timestamp.nanoseconds / 1000000
      );
      const dateB = new Date(
          b.latest[0].timestamp.seconds * 1000 +
          b.latest[0].timestamp.nanoseconds / 1000000
      );
      return dateB - dateA; // Compare the dates
  });
  preUserData = [...usersWithLatest, ...usersWithoutLatest];
  setPreUsers(preUserData);
};


  const [dataFetched, setDataFetched] = useState(false);

  const fetchData = async () => {
    await Promise.all([fetchUsers(), fetchPreUsers()]);
    setDataFetched(true);
  };

  useEffect(() => {
    fetchData();
  }, [invitedUsersCount]);

  const fetchArchivedMatches = async (user) => {
    if (!user.archivedMatches || user.archivedMatches.length < 1) {
      setArchivedMatches([]);
      setArchivedMatchesLoaded(true);
      return;
    }
    const archivedMatches = user.archivedMatches;
    const promises = archivedMatches.map(async (matchId) => {
      const docRef = doc(db, "users", matchId);
      const docSnap = await getDoc(docRef);
      let match = docSnap.data();
      const { email, formData } = match;
      const matchEmail = email;
      const matchCompany =
        formData && formData.company && formData.company.company
          ? formData.company.company
          : "Unknown Company";

      // Modificar el objeto match original
      match.email = matchEmail;
      match.company = matchCompany;
      delete match.formData;

      return match;
    });
    const newData = await Promise.all(promises);
    setArchivedMatches(newData);
    setArchivedMatchesLoaded(true);
  };

  const handleArchived = (user) => {
    fetchArchivedMatches(user);
    setArchivedPopup(true);
    setArchivedMatchesLoaded(false); // Resetea el estado cuando se abre el popup
  };

  const fetchNotInterestedMatches = async (user) => {
    if (!user.notInterestedMatches || user.notInterestedMatches.length < 1) {
      setNotInterestedMatches([]);
      setNotInterestedMatchesLoaded(true);
      return;
    }
    const notInterestedMatches = user.notInterestedMatches;
    const promises = notInterestedMatches.map(async (matchId) => {
      const docRef = doc(db, "users", matchId);
      const docSnap = await getDoc(docRef);
      let match = docSnap.data();
      const { email, formData } = match;
      const matchEmail = email;
      const matchCompany =
        formData && formData.company && formData.company.company
          ? formData.company.company
          : "Unknown Company";

      // Modificar el objeto match original
      match.email = matchEmail;
      match.company = matchCompany;
      delete match.formData;

      return match;
    });
    const newData = await Promise.all(promises);
    setNotInterestedMatches(newData);
    setNotInterestedMatchesLoaded(true);
  };

  const handleNotInterested = (user) => {
    fetchNotInterestedMatches(user);
    setNotInterestedPopup(true);
    setNotInterestedMatchesLoaded(false); // Resetea el estado cuando se abre el popup
  };

  const fetchPendingMatches = async (user) => {
    if (!user.pendingMatches || user.pendingMatches.length < 1) {
      setPendingMatches([]);
      setPendingMatchesLoaded(true);
      return;
    }
    const pendingMatches = user.pendingMatches;
    const promises = pendingMatches.map(async (matchId) => {
      const docRef = doc(db, "users", matchId);
      const docSnap = await getDoc(docRef);
      let match = docSnap.data();
      const { email, formData } = match;
      const matchEmail = email;
      const matchCompany =
        formData && formData.company && formData.company.company
          ? formData.company.company
          : "Unknown Company";

      // Modificar el objeto match original
      match.email = matchEmail;
      match.company = matchCompany;
      delete match.formData;

      return match;
    });
    const newData = await Promise.all(promises);
    setPendingMatches(newData);
    setPendingMatchesLoaded(true);
  };

  const handlePending = (user) => {
    fetchPendingMatches(user);
    setPendingPopup(true);
    setPendingMatchesLoaded(false); // Resetea el estado cuando se abre el popup
  };

  const fetchInterestedMatches = async (user) => {
    let interestedMatches = [];
    if (user.category === "investor") {
        interestedMatches = user.interestedMatches || [];
    } else if (user.category === "startup") {
        const investorInterests = user.investorInterests || [];
        const investorContacts = user.investorContacts || [];
        const investorContactIds = investorContacts.map(contact => contact.investorId);
        interestedMatches = [...investorInterests, ...investorContactIds];
    }

    if (interestedMatches.length === 0) {
        setInterestedMatches([]);
        setInterestedMatchesLoaded(true);
        return;
    }

    const promises = interestedMatches.map(async (matchId) => {
        const docRef = doc(db, "users", matchId);
        const docSnap = await getDoc(docRef);
        let match = docSnap.data();
        const { email, formData } = match;
        const matchEmail = email;
        const matchCompany = formData && formData.company && formData.company.company ? formData.company.company : "Unknown Company";

        // Modificar el objeto match original
        match.email = matchEmail;
        match.company = matchCompany;
        delete match.formData;

        return match;
    });
    const newData = await Promise.all(promises);
    setInterestedMatches(newData);
    setInterestedMatchesLoaded(true);
};

const handleInterested = (user) => {
  fetchInterestedMatches(user);
  setInterestedPopup(true);
  setInterestedMatchesLoaded(false); // Resetea el estado cuando se abre el popup
};


  const handleInvite = async (user) => {
    try {
      if (user && user.id) {
        const userRef = doc(db, "users", user.id);
        await updateDoc(userRef, { invited: true });
      } else {
        console.error("Invalid user object:", user);
      }
    } catch (error) {
      console.error("Error updating user:", error);
      throw error; // Propagate the error to the calling function
    }
  };

  const sendInvitationCode = async (user) => {
    try {
      const { username, code, email } = user;

      const payload = {
        sender: {
          name: "PRAIRI",
          email: "invite@prairi.com",
        },
        to: [
          {
            username,
            email,
          },
        ],
        templateId: 27, //define conditional, 27 for startup, 70 for investor
        params: {
          NAME: username,
          CODE: code,
          DOCID: user.id,
        },
      };
      await axios({
        method: "POST",
        url: "https://api.sendinblue.com/v3/smtp/email",
        headers: {
          Accept: "application/json",
          "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      });
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  const invite = async (user) => {
    try {
      await handleInvite(user);
      sendInvitationCode(user);
      setInvitedUsersCount(invitedUsersCount + 1);
      console.log("Invitation sent successfully!");
    } catch (error) {
      console.error("Error inviting user:", error);
    }
  };

  if (isAdminUser) {
    return (
      <div
        className="px-4 flex flex-col justify-center items-center  pt-10"
        style={{ width: "100%", marginTop: navBarHeight + 4 }}
      >

        {dataFetched && (
          <div className="flex flex-col">
            <div className="flex justify-center mb-4">
              <PrairiButton label="View Completed profiles" size="lg" border={showComplete?"green":"silver"} click={handleShowComplete}/>
              <PrairiButton label="View Incomplete profiles" size="lg" border={showIncomplete?"green":"silver"} click={handleShowInomplete}/>
            </div>
            <div className="flex">
          <div className="my-7">
            {completedUsers.map((user, index) => (
              <div className=" mx-5 my-2">
                <UserTracking
                  key={index}
                  user={{ id: user.id, ...user }}
                  reminder={() => console.log("reminder")}
                  invite={() => invite(user)}
                  seeArchived={() => handleArchived(user)}
                  seeNotInterested={()=>handleNotInterested(user)}
                  seePending={()=>handlePending(user)}
                  seeInterested={()=>handleInterested(user)}
                  showDetails={showComplete}
                />
              </div>
            ))}
          </div>
          <div className={showComplete?"my-7":""}>
          {showIncomplete && <div className="flex w-[250px]  ml-[236px] justify-between text-[9px]">
            <div className=" text-center">
              <p>Approved</p>
            </div>
            <div className="text-center">
            <p>Clicked link</p>
            
            </div>
            <div className=" text-center">
            <p>Registered</p>
            </div>
            <div className=" text-center">
              <p>Agreed to terms</p>
            </div>
            <div className=" text-center">
              <p>Working on profile</p>
            </div>
          </div>}
            {preUsers.map((user, index) => (
              <div className=" my-2">
                <UserTracking
                  key={index}
                  user={{ id: user.id, ...user }}
                  reminder={() => console.log("reminder")}
                  invite={() => invite(user)}
                  seeArchived={() => handleArchived(user)}
                  seeNotInterested={()=>handleNotInterested(user)}
                  seePending={()=>handlePending(user)}
                  seeInterested={()=>handleInterested(user)}
                  showDetails={showIncomplete}
                />
              </div>
            ))}
          </div>
          </div>
          </div>
        )}

        <Modal
          open={archivedPopup && archivedMatchesLoaded}
          border="yellow"
          size="xl"
        >
          <div className="pb-5">
            <div className="flex mb-5 justify-end ">
              <TextButton
                label="Close"
                color="yellow"
                click={() => setArchivedPopup(false)}
              />
            </div>

            {archivedMatches.map((match, index) => (
              <div className="mb-2">
                <MatchDetail match={match} status="Archived" />
              </div>
            ))}
          </div>
        </Modal>

        <Modal
          open={notInterestedPopup && notInterestedMatchesLoaded}
          border="red"
          size="xl"
        >
          <div className="pb-5">
            <div className="flex mb-5 justify-end ">
              <TextButton
                label="Close"
                color="red"
                click={() => setNotInterestedPopup(false)}
              />
            </div>

            {notInterestedMatches.map((match, index) => (
              <div className="mb-2">
                <MatchDetail match={match} status="Not interested" />
              </div>
            ))}
          </div>
        </Modal>

        <Modal
          open={pendingPopup && pendingMatchesLoaded}
          border="silver"
          size="xl"
        >
          <div className="pb-5">
            <div className="flex mb-5 justify-end ">
              <TextButton
                label="Close"
                color="silver"
                click={() => setPendingPopup(false)}
              />
            </div>

            {pendingMatches.map((match, index) => (
              <div className="mb-2">
                <MatchDetail match={match} status="Pending" />
              </div>
            ))}
          </div>
        </Modal>

        <Modal
          open={interestedPopup && interestedMatchesLoaded}
          border="green"
          size="xl"
        >
          <div className="pb-5">
            <div className="flex mb-5 justify-end ">
              <TextButton
                label="Close"
                color="green"
                click={() => setInterestedPopup(false)}
              />
            </div>

            {interestedMatches.map((match, index) => (
              <div className="mb-2">
                <MatchDetail match={match} status="Interested" />
              </div>
            ))}
          </div>
        </Modal>
      </div>
    );
  } else {
    return <>Access forbidden</>;
  }
};

export default Users;
