import React, { useState, useContext } from "react";
import { navBarContext } from "./context/context";
import { storage } from "../firebase/firebaseApp";
import { auth, confirmPasswordReset } from "../firebase/firebaseApp";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { updateButtonClickCount, sendBugEmails } from "../utils";
import { BugTab } from "@peterfosso/prairi-components";
function PasswordReset() {
  const { navBarHeight } = useContext(navBarContext);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState(null);
  const [bugError, setBugError] = useState(null);

  const handleUpload = (e) => {
    return new Promise((resolve, reject) => {
      const selectedFile = e.target.files[0];

      const storageRef = ref(storage, `bugs/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
        (error) => { // Manejador de error
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => { // Manejador de éxito
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
          console.log("uploaded correctly");
          setScreenshotUrl(downloadURL);
        }
      );
      
    });
  };

  const handleBugText = (e) => {
    const value = e.target.value;
    setBugText(value);
    if (value !== "") {
      setBugError("");
    }
  };
  
  const handleBugEmail = (e) => {
    const value = e.target.value;
    setBugEmail(value);
    if (value !== "") {
      setBugError("");
    }
  };
  
  const validateFields = () => {
    if (!bugText || bugText.trim() === "") {
      setBugError("Please describe the bug.");
      return false;
    }
    if (!bugEmail || bugEmail.trim() === "") {
      setBugError("We need your email address.");
      return false;
    }
    return true;
  };
  
const handleUploadAndSendEmail = async (file) => {
  try {
    if (!validateFields()) return;

    let uploadedUrl = screenshotUrl;
    if (file) {
      uploadedUrl = await handleUpload({ target: { files: [file] } });
    }

    console.log(`screenshotUrl ${uploadedUrl}`);
    sendBugEmails(bugEmail, "", bugText, "Build", uploadedUrl || "");
  } catch (error) {
    console.error("Error uploading file or sending email:", error);
  }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (password !== confirmPassword) {
        setErrorMessage("Passwords do not match");
        return;
      }
      await confirmPasswordReset(auth);
    }
    catch (error) {
    }

    const urlParams = new URLSearchParams(window.location.search);

    const oobCode = urlParams.get("oobCode");
    confirmPasswordReset(auth, oobCode, password).then(function () {
      alert("Password reset successful");
      window.location.href = "/login";
    }).catch(function (error) {
      setErrorMessage("Password reset failed: " + error.message);
    });
  };

  return (
    <div
      id="forgotPw__"
      style={{
        marginTop: navBarHeight + 70,
        marginBottom: navBarHeight + 10,
        width: "100%",
        minWidth: "366px",
        padding: "2%",
      }}
    >
            <BugTab
                      email={bugEmail}
                      
                      setEmail={handleBugEmail}
                      setText={handleBugText}
                      submit={(file) =>
                        handleUploadAndSendEmail(file)
                      }
                      error={bugError}
                      handleFileChange={handleUpload}
                    />
      <section
        className={`
          bg-#1e1e1d
          shadow-black mx-auto
          w-11/12 md:max-w-lg
          rounded-md
          py-2.5 px-5
          shadow-md
        `}
      >
        <h1 className="mb-4 text-center text-xl text-gray-500 uppercase">
          Reset Your Password
        </h1>
        <form className="py-0 px-5" onSubmit={handleSubmit}>
          <div className="mt-5">
            <label htmlFor="password">New Password</label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="newpassword"
              name="newpassword"
              id="newpassword"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
              required
            />
          </div>
          <div className="mt-5" >
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
              required
            />
            {password.length > 0 && password.length < 6 && (
              <p className="text-red-500 text-sm mt-2">
                Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.
              </p>
            )}
          </div>
          <div className="my-2.5 mx-0 text-center">
            <input
              type="submit"
              className="
        uppercase bg-[#16a34a24] w-full 
        my-2 cursor-pointer rounded 
        border border-green-500 p-2.5 
        text-base text-white outline-none 
        transition-all duration-300 ease-in-out 
        hover:scale-105 hover:bg-green-600 hover:border-white"
              value="Reset Password"
            />
          </div>
        </form>

        {errorMessage && <p>{errorMessage}</p>}
      </section>
      )
    </div>
  );
};

export default PasswordReset;
