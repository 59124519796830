import React, { useContext, useState, useEffect } from "react";
import { navBarContext, authContext } from "./context/context";
import { useNavigate } from "react-router-dom";
import Settings from "./Settings";
import {
  StartupButton,
  StartupProfile,
  Loading,
  CompleteYourProfile,
  BugTab,
} from "@peterfosso/prairi-components";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "../firebase/firebaseApp";
import { useMediaQuery } from "react-responsive";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import { sendBugEmails } from "../utils";

const DataProfileStartup = (props) => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const [docId, setDocId] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [currentUserData, setCurrentUserData] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState("");
  const [bugError, setBugError] = useState(null);
  let navigate = useNavigate();

  const redirectToLogin = () => {
    navigate("/login"); // Redirect to the login page
  };

  const redirectToForms = () => {
    //search for current position of the user in the forms
    navigate("/startupregistration");
  };

  const handleUpload = (e) => {
    return new Promise((resolve, reject) => {
      const selectedFile = e.target.files[0];

      const storageRef = ref(storage, `bugs/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
        (error) => {
          // Manejador de error
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => {
          // Manejador de éxito
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
          console.log("uploaded correctly");
          setScreenshotUrl(downloadURL);
        }
      );
    });
  };

  const handleBugText = (e) => {
    const value = e.target.value;
    setBugText(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const handleBugEmail = (e) => {
    const value = e.target.value;
    setBugEmail(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const validateFields = () => {
    if (!bugText || bugText.trim() === "") {
      setBugError("Please describe the bug.");
      return false;
    }
    return true;
  };
  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;
  
      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUpload({ target: { files: [file] } });
      }
  
      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(
        currentUserData.email,
        currentUserData.contactName,
        bugText,
        "data profile startup",
        uploadedUrl || ""
      );
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };
  

  useEffect(() => {
    if (dataFetched && !currentUser) {
      redirectToLogin();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setDocId(currentUser.uid);
    }
  }, [currentUser]);

  const getDataFromUser = async () => {
    try {
      // Get user data from email
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      setCurrentUserData(userData);
      return { userData, userId };
    } catch (error) {
      //console.log("error in getDataFromUser");
    }
  };

  const fetchData = async () => {
    try {
      if (currentUser) {
        await getDataFromUser(); // Pass the UID from currentUser
        setDataFetched(true);
      }
    } catch (error) {
      //console.log('error fetching data' + error);
    }
  };

  useEffect(() => {
    fetchData();
    //console.log("data fetched?" + JSON.stringify(dataFetched))
    //console.log(currentUserData)
  }, [currentUser]);

  const [showSettings, setShowSettings] = useState(false);
  const handleSettings = () => {
    setShowSettings(true);
    navigate("/settings");
  };

  if (!dataFetched) {
    return (
      <div
        id="newMatch__"
        style={{
          marginTop: navBarHeight,
          marginBottom: navBarHeight + 30,
          width: "100%",
          minWidth: "366px",
          padding: "2%",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div
      id="newMatch__"
      style={{
        marginTop: navBarHeight,
        marginBottom: navBarHeight + 10,
        width: "100%",
        minWidth: "366px",
        padding: "2%",
      }}
    >
      {currentUserData && (
        <BugTab
          email={currentUserData.email}
          name={currentUserData.contactName}
          setEmail={handleBugEmail}
          setText={handleBugText}
          submit={(file) => handleUploadAndSendEmail(file)}
          error={bugError}
          handleFileChange={handleUpload}
        />
      )}
      <section
        id="newMatch__container"
        className={`
        w-9/12 max-w-[560px] 

        m-auto  rounded-md
        py-2.5 px-5 
        min-w-[380px]
       `}
      >
        {dataFetched && currentUserData.formData && (
          <StartupProfile basic isMobile={isMobile} startup={currentUserData} />
        )}
      </section>
      {dataFetched && (
        <CompleteYourProfile
          open={
            !currentUserData.formData ||
            !currentUserData.formData.matching.completed
          }
          click={redirectToForms}
        />
      )}
    </div>
  );
};
export default DataProfileStartup;
