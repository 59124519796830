import React, { useContext, useEffect, useState } from "react";
import ProfileComplete from "../assets/profile_complete.png";
import Logo from "../assets/prairiwhite.png";
import Phone from "../assets/phone.png";
import { investorContext, authContext, navBarContext } from "./context/context";
import { useMediaQuery } from "react-responsive";
import { getDataFromUser } from "../utils";

import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
  Timestamp,
  addDoc,
} from "../firebase/firebaseApp";

const ExpressMatching = () => {

  const [dataFetched, setDataFetched] = useState(false)
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const { navBarHeight } = useContext(navBarContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { currentUser } = useContext(authContext);

  

    
    const sendLoginSMS = async (userData, userId) => { //Send ONLY ONCE. So we'll store that.
      try {
        const docRef = doc(db, "users", userId);
        await updateDoc(docRef, { loginSMS: true });
        const messagesCollection = collection(db, "messages");
        const dataToStore = {
          to: `+${userData.phone}`,
          body: `Hey, ${userData.contactName.split(' ')[0]}! Congratulations on completing your profile. Don't forget to log in on your phone :) http://prairi.com`,
        };
        await addDoc(messagesCollection, dataToStore);
      } catch (error) {
        console.log(error);
      }
    };
    


    return (
      <div className="shadow-grey w-full rounded-md mt-2 h-full md:max-w-xl mx-auto py-2.5 pb-[300px]" style={{ paddingTop: navBarHeight }}>
        <div className="text-center mt-5">
          <img className="h-16 mb-1 mx-auto" src={Logo} alt="Matching" />
          <h1 className="font-bold text-center mb-8 text-base">
            Matching Starts 
          </h1>
        </div>

        <div className="flex">
          <img src={ProfileComplete} className="w-8 h-8 ml-2" alt="tick" />
          <div className="ml-5 mr-3">
            <p className="font-bold mb-3">You can now be matched!</p>
            <p className="mb-3 ">
              PRAIRI will now find you startups you’ll love, based on your
              criteria.
            </p>
            <p className="mb-3 ">
              You’ll be notified via email and SMS (but not more than once per
              day) whenever there’s a match. Pause notifications/results anytime by going to your Settings.
            </p>
            <p className="mb-3">You can now close this and go back to building your portfolio.</p>
          </div>
        </div>

        {/*Here goes the phone stuff*/}

        {!isMobile && (
          <div className="flex mt-8 items-end">
            <img src={Phone} className=" pb-2 ml-2 w-1/5" alt="phone icon" />
            <div className="ml-5 mr-3">
              <p className="font-bold mb-3">One final step <span className=" text-lg">...</span></p>
              <p className="mb-3 ">
                We just sent you an SMS (or may arrive soon)! Click the link to
                open PRAIRI on your mobile phone so you'll be logged in and
                ready to be notified whenever we have matches for you.
              </p>
            </div>
          </div>
        )}
      </div>
    );

};

export default ExpressMatching;
