import React, { useState, useEffect, useContext } from "react";
import Slider from "rc-slider";
import {fetchExchangeRates} from "../../utils"
import "rc-slider/assets/index.css";
import {
  collection,
  db,
  getDoc,
  doc,
  Timestamp,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
} from "../../firebase/firebaseApp";
import IndustryLogo from "../../assets/industry.png";
import CurrencyFormat from "react-currency-format";
import { updateLatestActions } from "../../utils";
import { useMediaQuery } from "react-responsive";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { dataContext, authContext } from "../context/context";
import NextButton from "../common/NextButton/NextButton";
import {
  Modal,
  ImageGridItem,
  TextButton,
  PrairiButton,
} from "@peterfosso/prairi-components";
import NoPoverty from "../../assets/SDG/E-WEB-Goal-01.png";
import ZeroHunger from "../../assets/SDG/E-WEB-Goal-02.png";
import GoodHealth from "../../assets/SDG/E-WEB-Goal-03.png";
import QualityEducation from "../../assets/SDG/E-WEB-Goal-04.png";
import GenderEquality from "../../assets/SDG/E-WEB-Goal-05.png";
import CleanWater from "../../assets/SDG/E-WEB-Goal-06.png";
import CleanEnergy from "../../assets/SDG/E-WEB-Goal-07.png";
import DecentWork from "../../assets/SDG/E-WEB-Goal-08.png";
import Innovation from "../../assets/SDG/E-WEB-Goal-09.png";
import ReducedInequalities from "../../assets/SDG/E-WEB-Goal-10.png";
import SustainableCities from "../../assets/SDG/E-WEB-Goal-11.png";
import ResponsibleConsumption from "../../assets/SDG/E-WEB-Goal-12.png";
import ClimateAction from "../../assets/SDG/E-WEB-Goal-13.png";
import LifeBelowWater from "../../assets/SDG/E-WEB-Goal-14.png";
import LifeOnLand from "../../assets/SDG/E-WEB-Goal-15.png";
import Peace from "../../assets/SDG/E-WEB-Goal-16.png";
import Partnerships from "../../assets/SDG/E-WEB-Goal-17.png";
import SDG from "../../assets/SDG/SDGs-transparent.png";

const Industry = (props) => {
  const { setDataFunc, globalData } = useContext(dataContext);
  const industryData = globalData.formData.industry;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [userRef, setUserRef] = useState([])
  //const [rates, setRates] = useState(null)
  const [industryCount, setIndustryCount] = useState(1);
  const rates = {
    USD: {
      EUR: 0.95,
      SEK: 10.91,
      GBP: 0.78,
    },
    EUR: {
      USD: 1.05,
      SEK: 11.46,
      GBP: 0.82,
    },
    SEK: {
      USD: 0.09,
      EUR: 0.09,
      GBP: 0.07,
    },
    GBP: {
      USD: 1.26,
      EUR: 1.2,
      SEK: 13.82,
    },
  };
  const { currentUser } = useContext(authContext);
  let isAmplified;
  if (industryData.currency === "SEK") {
    if (industryData.amount > 100000000) {
      isAmplified = true;
    } else {
      isAmplified = false;
    }
  }
  if (industryData.currency !== "SEK") {
    if (industryData.amount > 10000000) {
      isAmplified = true;
    } else {
      isAmplified = false;
    }
  }
  const [amplified, setAmplified] = useState(isAmplified);
  const [showSecondIndustry, setShowSecondIndustry] = useState(
    industryData.industry2 !== ""
  );



  const handleAddSecondIndustry = () => {
    if (industryCount === 0) {
      setIndustryCount(1);
      setShowSecondIndustry(true);
    } else if (industryCount === 1) {
      setShowSecondIndustry(true);
    }
  };

  const toggleAmplify = () => {
    setAmplified(!amplified);
  };

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const email = currentUser?.email;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      let ref = doc(db, "users", userId)
      return { userData, userId, ref };
    } catch (error) {
      // console.log(error);
      return { userData: null };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try{
        const { ref } = await getDataFromDb()
        setUserRef(ref)
      } catch{
        console.log(error)
      }
    }
fetchData()
  }, [currentUser])



  // useEffect(() => {
  //   const fetchRates = async () => {
  //     const rates = await fetchExchangeRates();
  //     if (rates) {
  //       setRates(rates);
  //     }
  //   };
  
  //   fetchRates();
  // }, []);
  
  
  

  const convertCurrency = (amount, fromCurrency, toCurrency) => {
    if (!rates || !rates[fromCurrency] || !rates[fromCurrency][toCurrency]) {
      return "Loading...";
    }
  
    if (fromCurrency === toCurrency) {
      return amount;
    }
  
    const rate = rates[fromCurrency][toCurrency];
    return (amount * rate);
  };

  const handleMinusSecondIndustry = () => {
    if (industryCount === 1) {
      setIndustryCount(0);
      setShowSecondIndustry(false);
      setData((prevData) => ({ ...prevData, industry2: "" }));
    } else if (industryCount > 1) {
      setIndustryCount((prevCount) => prevCount - 1);
    }
  };

  const [data, setData] = useState({
    industry: industryData.industry || "",
    stage: industryData.stage || "",
    currency: industryData.currency || "",
    industry2: industryData.industry2 || "",
    amount: industryData.amount || "",
    model: industryData.model || "",
    goalsBool: industryData.goalsBool || false,
    goals: industryData.goals || "",
    type: industryData.type || "",
    error: "",
    loading: false,
    message: "",
  });

  let navigate = useNavigate();
  const currencySelectOptions = ["EUR", "USD", "GBP", "SEK"];

  const industrySelectOptions = [
    { name: "Select your industry", value: "" },
    { name: "AdTech/Media", value: "AdTech/Media" },
    { name: "Big Data", value: "Big Data" },
    { name: "Consumer Goods", value: "Consumer Goods" },
    { name: "Cybersecurity", value: "Cybersecurity" },
    {
      name: "Deep Tech (AI, Web3, Blockchain, etc.)",
      value: "Deep Tech (AI, Web3, Blockchain, etc.)",
    },
    {
      name: "Emerging Tech (NFTs, VR, Robotics)",
      value: "Emerging Tech (NFTs, VR, Robotics)",
    },
    { name: "eCommerce", value: "eCommerce" },
    { name: "EdTech", value: "EdTech" },
    { name: "Entertainment/Music/Gaming", value: "Entertainment/Music/Gaming" },
    { name: "Fashion", value: "Fashion" },
    { name: "FoodTech", value: "FoodTech" },
    { name: "Fintech/Finance", value: "Fintech/Finance" },
    { name: "Greentech/Cleantech/Energy", value: "Greentech/Cleantech/Energy" },
    { name: "HealthTech/MedTech", value: "HealthTech/MedTech" },
    { name: "IT/Software/SaaS", value: "IT/Software/SaaS" },
    { name: "PropTech", value: "PropTech" },
    { name: "Retail", value: "Retail" },
    { name: "Service Industry Solutions", value: "Service Industry Solutions" },
    { name: "Space Technology", value: "Space Technology" },
    {
      name: "Transport and Logistics (Shared Mobility/Maritime/Delivery Services)",
      value:
        "Transport and Logistics (Shared Mobility/Maritime/Delivery Services)",
    },
  ];

  const stageSelectOptions = [
    { name: "Select your stage of development", value: "" },
    { name: "Early/Seed", value: "Early/Seed" },
    { name: "Growth", value: "Growth" },
    { name: "Series A", value: "Series A" },
    { name: "Series B/Higher", value: "Series B/Higher" },
  ];

  const modelSelectOptions = [
    { name: "Select your business model", value: "" },
    { name: "B2C", value: "B2C" },
    { name: "B2B", value: "B2B" },
    { name: "B2B and B2C", value: "B2B and B2C" },
  ];

  const productTypeSelectOptions = [
    {name: "Select from the following", value: ""},
    {name: "Software", value: "Software"},
    {name: "Hardware", value: "Hardware"},
    {name: "SaaS", value: "SaaS"},
    {name: "Other", value: "Other"}
  ]

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const time = Timestamp.fromDate(new Date())
    const latestTime = time.toDate().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
    if (!industry || !stage || !currency || !amount || !model || !type) {
      setData({ ...data, error: "All fields are required." });
    } else {
      setData({
        ...data,
        error: "",
        loading: true,
        message: "Saving... (click progress bar if you wish to edit)",
      });

      setTimeout(() => {
        setDataFunc({
          ...globalData,
          formData: {
            ...globalData.formData,
            industry: {
              ...globalData.formData.industry,
              industry,
              industry2,
              stage,
              type,
              currency,
              amount,
              model,
              completed: true,
            },
          },
        });
        updateLatestActions(userRef, "Saved industry form", isMobile)
        navigate("/startupregistration/founders");
      }, 2000);
    }
  };


  const {
    industry,
    stage,
    error,
    message,
    currency,
    type,
    amount,
    industry2,
    model,
  } = data;

  useEffect(() => {
    // Update slider value when currency changes
    let newAmount = convertCurrency(
      industryData.amount,
      industryData.currency,
      data.currency
    );
    if (data.currency === "SEK") {
      if (newAmount < 1000000) {
        newAmount = 1000000;
      }
      if (newAmount > 100000000) {
        newAmount = 100000000;
      }
    } else {
      if (newAmount < 100000) {
        newAmount = 100000;
      }
      if (newAmount > 10000000) {
        newAmount = 10000000;
      }
    }
    setData({
      ...data,
      amount: newAmount,
    });
  }, [currency]);

  useEffect(() => {
    // Set initial slider value based on the currency when the component mounts
    setData({
      ...data,
      amount: industryData.amount,
    });
  }, []);

  return (
    <div className="shadow-black w-[90%] rounded-md mt-2 md:max-w-xl mx-auto py-2.5">
      <div className="my-2">
        <div className="flex items-end pl-3 md:pl-0">
          <div
            className={`h-10 md:h-12 flex items-center justify-center mr-2 md:mr-4`}
          >
            <img className="h-full" src={IndustryLogo} alt="Company" />
          </div>
          <h1 className="text-base md:text-xl text-white  uppercase font-bold">
            Industry
          </h1>
        </div>
        <form className="" onSubmit={handleSubmit}>
          <div className="mt-5 px-3 md:pl-24">
            <div className="flex justify-between items-center my-2">
              <h1 className="mr-4">Industry</h1>
              <div className="flex items-center flex-row-reverse "></div>
            </div>
            <div className="flex items-center mb-5">
              <select
                className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
                name="industry"
                id="industry"
                value={industry}
                onChange={handleChange}
              >
                {industrySelectOptions.map((option, index) => {
                  return (
                    <option
                      key={option.value}
                      value={option.value}
                      disabled={index === 0 ? true : false}
                    >
                      {option.name}
                    </option>
                  );
                })}
              </select>

              <div>
                <button
                  type="button"
                  className="uppercase bg-navBg cursor-pointer rounded border border-gray-500 flex items-center justify-center w-10 h-10 text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 mt-2.5"
                  onClick={handleAddSecondIndustry}
                >
                  +
                </button>
              </div>
            </div>

            <div>
              {showSecondIndustry &&
                Array.from({ length: industryCount }).map((_, index) => (
                  <div key={index}>
                    {index === 0 ? (
                      <label htmlFor="industry2">
                        2nd Industry [optional] [NOT RECOMMENDED unless you are
                        definitely in two ]
                      </label>
                    ) : (
                      <label htmlFor="industry2"> </label>
                    )}

                    <div className="flex justify-between items-center">
                      <select
                        className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
                        name="industry2"
                        id="industry2"
                        value={industry2}
                        onChange={handleChange}
                      >
                        {industrySelectOptions.map((option, index) => {
                          return (
                            <option
                              key={option.value}
                              value={option.value}
                              disabled={index === 0 ? true : false}
                            >
                              {option.name}
                            </option>
                          );
                        })}
                      </select>
                      <button
                        type="button"
                        className="uppercase bg-navBg cursor-pointer rounded border border-gray-500 flex row items-center justify-center w-10 h-10 text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 mt-2.5"
                        onClick={handleMinusSecondIndustry}
                      >
                        -
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="model">Business model</label>
            <select
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              name="model"
              id="model"
              value={model}
              onChange={handleChange}
            >
              {modelSelectOptions.map((option, index) => {
                return (
                  <option
                    key={option.value}
                    value={option.value}
                    disabled={index === 0 ? true : false}
                  >
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>


          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="model">Product primary focus</label>
            <select
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              name="type"
              id="type"
              value={type}
              onChange={handleChange}
            >
              {productTypeSelectOptions.map((option, index) => {
                return (
                  <option
                    key={option.value}
                    value={option.value}
                    disabled={index === 0 ? true : false}
                  >
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="stage">Stage</label>
            <select
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              name="stage"
              id="stage"
              value={stage}
              onChange={handleChange}
            >
              {stageSelectOptions.map((option, index) => {
                return (
                  <option
                    key={option.value}
                    value={option.value}
                    disabled={index === 0 ? true : false}
                  >
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="my-5 ">
            <h1 className=" text-base pl-3 md:pl-24 md:text-xl text-white uppercase font-bold">
              Investment Needed
            </h1>
            <div className="">
              <div className="mt-5 px-3 md:pl-24">
                <h1 className="my-5">Currency</h1>
                <div className="inline-flex items-center bg-buttonParent  py-1 pl-2">
                  {currencySelectOptions.map((currentCurrency, index) => {
                    return (
                      <input
                        className={`uppercase mr-1 ${
                          currentCurrency === currency
                            ? "bg-[#303030] opacity-100 border border-[#00b0f0] "
                            : "bg-inactiveButton opacity-50  "
                        } cursor-pointer rounded  text-xs py-1 px-4 border-solid text-white `}
                        key={currency + index}
                        type="button"
                        value={currentCurrency}
                        name="currency"
                        onClick={handleChange}
                      ></input>
                    );
                  })}
                </div>
              </div>
              <div className="mt-5 px-3 md:pl-24">
                <div className="flex items-center">
                  <div className="w-9/12  ">
                    {currency === "SEK" && (
                      <div className="flex items-center">
                        <Slider
                          step={amplified ? 1000000 : 100000}
                          min={amplified ? 10000000 : 1000000}
                          max={amplified ? 1000000000 : 100000000}
                          allowCross={false}
                          defaultValue={amount}
                          value={amount}
                          onChange={(amount) => setData({ ...data, amount })}
                          trackStyle={[
                            { backgroundColor: "#00b0f0", height: "6px" },
                          ]}
                          railStyle={{ backgroundColor: "grey", height: "6px" }}
                          handleStyle={[
                            {
                              height: "15px",
                              width: "15px",
                              borderColor: "grey",
                              boxShadow: "none",
                              borderWidth: "0px",
                              borderRadius: "2px",
                              backgroundColor: "grey",
                              outline: "none",
                              opacity: 1,
                            },
                          ]}
                        />
                        <button
                          type="button"
                          onClick={toggleAmplify}
                          className={`text-xs p-1 ml-4 border rounded-md bg-[#303030] ${
                            amplified
                              ? "border-bluePrairi border-solid"
                              : "border-[#303030]"
                          }`}
                        >
                          +10x
                        </button>
                      </div>
                    )}
                    {currency !== "SEK" && (
                      <div className="flex items-center">
                        <Slider
                          step={amplified ? 1000000 : 100000}
                          min={amplified ? 1000000 : 100000}
                          max={amplified ? 100000000 : 10000000}
                          allowCross={false}
                          defaultValue={amount}
                          value={amount}
                          onChange={(amount) => setData({ ...data, amount })}
                          trackStyle={[
                            { backgroundColor: "#00b0f0", height: "6px" },
                          ]}
                          railStyle={{ backgroundColor: "grey", height: "6px" }}
                          handleStyle={[
                            {
                              height: "15px",
                              width: "15px",
                              borderColor: "grey",
                              boxShadow: "none",
                              borderWidth: "0px",
                              borderRadius: "2px",
                              backgroundColor: "grey",
                              outline: "none",
                              opacity: 1,
                            },
                          ]}
                        />
                        <button
                          className={`text-xs p-1 ml-4 border rounded-md bg-[#303030] ${
                            amplified
                              ? "border-bluePrairi border-solid"
                              : "border-[#303030]"
                          }`}
                          type="button"
                          onClick={toggleAmplify}
                        >
                          +10x
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="ml-4 font-bold flex items-center">
                    <CurrencyFormat
                      className="ml-2"
                      displayType="text"
                      thousandSeparator={true}
                      value={amount}
                    />
                    {((amount === 100000000 && currency === "SEK") ||
                      (amount === 10000000 && currency !== "SEK")) && (
                      <span className="ml-0">+</span>
                    )}
                    <span className="ml-2 ">{currency.toUpperCase()}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {error && <p className="my-2 px-3 md:pl-24 text-red-500">{error}</p>}
          {message && (
            <p className="my-2 px-3 md:pl-24 text-green-500">
              Saving...{" "}
              <span className="text-silverPrairi">
                (click progress bar if you wish to edit)
              </span>
            </p>
          )}
          <div className="flex justify-center my-5 px-3 md:pl-24">
            <NextButton value="SAVE" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Industry;
