import React, { useRef, useState, useContext, useEffect } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { authContext } from "./components/context/context";
import Success from "./components/Success";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Invitation from "./components/Invitation";
import NavBar from "./components/NavBar";
import Register from "./components/Register";
import StartupRegister from "./components/StartupRegister";
import LandingAd from "./components/LandingAd";
import InvestorRegister from "./components/InvestorRegister";
import Pitch from "./components/Pitch";
import Login from "./components/Login";
import Cancel from "./components/Cancel"
import Company from "./components/startup-registration/Company";
import Industry from "./components/startup-registration/Industry";
import Founders from "./components/startup-registration/Founders";
import Ownership from "./components/startup-registration/Ownership";
import Users from "./components/Users";
import Pitchdeck from "./components/Pitchdeck";
import Notifications from "./components/Notifications";
import Reject from "./components/Reject";
import CodeGenerator from "./components/CodeGenerator";
import Build from "./components/Build";
import Stats from "./components/Stats";
import InvitedInvitation from "./components/InvitedInvitation";
import Support from "./components/startup-registration/Support";
import StartupRegistration from "./components/startup-registration/StartupRegistration";
import InvestorRegistration from "./components/investor-registration/InvestorRegistration";
import InvestorCompany from "./components/investor-registration/InvestorCompany";
import InvestorIndustry from "./components/investor-registration/investorIndustry";
import InvestorFounders from "./components/investor-registration/InvestorFounders";
import InvestorOwnership from "./components/investor-registration/investorOwnership";
import InvestorSupport from "./components/investor-registration/investorSupport";
import InvestorAd from "./components/InvestorAd";
import Matching from "./components/startup-registration/Matching";
import InvestorMatching from "./components/investor-registration/InvestorMatching";
import RemoveConfirmation from "./components/RemoveConfirmation";
import Approve from "./components/Approve";
import Seeker from "./components/Seeker"
import StartupWelcome from "./components/StartupWelcome";
import InvestorWelcome from "./components/InvestorWelcome";
import MatchExample from "./components/MatchExample";
import Footer from "./components/Footer";
import Partner from "./components/Partner";
import Landing from "./components/Landing";
import { navBarContext } from "./components/context/context";
import AuthProvider from "./components/AuthProvider";
import ProtectedRoute from "./components/ProtectedRoute";
import Settings from "./components/Settings";
import LandingPage from "./components/LandingPage/LandingPage";
import Terms from "./components/common/Terms";
import NewMatch from "./components/NewMatch";
import Remove from "./components/Remove";
import ThatsAll from "./components/ThatsAll";
import DataProfileStartup from "./components/DataProfileStartup";
import DataProfileInvestor from "./components/DataProfileInvestor";
import NotMe from "./components/NotMe";
import Dashboard from "./components/Dashboard";
import Dashboard1 from "./components/Dashboard1";
import StartupDashboard from "./components/StartupDashboard";
import StartupDashboard1 from "./components/StartupDashboard";
import Admin from "./components/Admin";
import StartupNomatchDashboard from "./components/StartupNomatchDashboard";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
//import DashboardNavbar from "./components/DashboardNavbar";
import InvestorInterest from "./components/InvestorInterest";
import InvestorInterestContact from "./components/InvestorInterestContact";
import PasswordReset from "./components/PasswordReset";
import MatchPause from "./components/MatchPause";
import Otp from "./components/Otp";
import NotFound404 from "./components/NotFound404";
import Reactivate from "./components/Reactivate";
import { analytics } from "./firebase/firebaseApp";
import { getAnalytics, logEvent } from "firebase/analytics";
import TwilioConsent from "./components/TwilioConsent";
import SharedMatch from "./components/SharedMatch";
import SecondInvitation from "./components/SecondInvitation";
import SeekerReject from "./components/SeekerReject";
import StartupHome from "./components/StartupHome";
import InvestorHome from "./components/InvestorHome"
import AdLanding from "./components/AdLanding";
import AdLandingMobile from "./components/AdLandingMobile";
import Near from "./components/Near";
import DeckApproval from "./components/DeckApproval";
import ExpressSignUp from "./components/ExpressSignUp";
import BestResults from "./components/BestResults";
import ExpressMatching from "./components/ExpressMatching";

function App() {
  const [navBarHeight, setNavBarHeight] = useState();
  const [dashboardKey, setDashboardKey] = useState(0);
  const [bugEmail, setBugEmail] = useState("")
  const [bugText, setBugText] = useState("")

  const resetDashboard = () => {
    setDashboardKey((prevKey) => prevKey+1)
  }
  const navBarRef = useRef();
  const getNavBarHeight = (navHeight) => {
    setNavBarHeight(navHeight);
  };
  const { currentUser } = useContext(authContext);
  logEvent(analytics, "page_view", {
    page_path: "/invitation/startup",
  });

  logEvent(analytics, "page_view", {
    page_path: "/invitation/investor",
  });

  logEvent(analytics, "page_view", {
    page_path: "/startupwelcome",
  });

  logEvent(analytics, "page_view", {
    page_path: "/investorwelcome",
  });

  const handleBugText = (e) => {
    setBugText(e.target.value)
  }

  const handleBugEmail = (e) => {
    setBugEmail(e.target.value)
  }

  return (
    <HelmetProvider>
      <AuthProvider>
        <Router>
          <navBarContext.Provider
            value={{
              navBarHeight,
              navBar: navBarRef,
            }}
          >
            
            <div id="app__">
            {/* <BugTab setEmail={handleBugEmail} setText={handleBugText} submit={()=>sendBugEmail(bugEmail, bugText)}/> */}
              <Helmet>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=0.9"
                />
              </Helmet>
              <div id="app__container" style={{}}>

                <div
                  id="app__div"
                  className="min-h-screen  flex items-start justify-center text-white font-rubik max-w-screen max-w-[100vw]"
                >
                  
                  <NavBar
                    ref={navBarRef}
                    updatedNavBarHeight={(navHeight) =>
                      getNavBarHeight(navHeight)
                    }
                    onResetDashboard={resetDashboard}
                  />
                  
                  <Routes>
                    <Route path="/">
                      <Route index element={<LandingPage />} />
                      <Route path="partner" element={<Partner />} />
                      <Route path="invitation" element={<Invitation />}>
                        <Route path=":categoryName/:promoId" element={<Invitation />} />
                      </Route>
                      <Route path="login" element={<Login />} />
                      <Route
                        path="forgotpassword"
                        element={<ForgotPassword />}
                      ></Route>
                      {/* <Route
                        path="changepassword"
                        element={<ChangePassword />}
                      ></Route> */}
                      <Route path="register" element={<Register />}>
                        <Route path=":docId" element={<Register />} />
                      </Route>
                      <Route path="create" element={<StartupRegister />}>
                        <Route path=":docId" element={<StartupRegister />} />
                      </Route>
                      <Route path="investorregister" element={<InvestorRegister />}>
                        <Route path=":docId" element={<InvestorRegister />} />
                      </Route>
                      <Route path="success" element={<Success />}>
                        <Route path=":docId" element={<Success />} />
                      </Route>
                      <Route path="newdeck" element={<DeckApproval />}>
                        <Route path=":docId" element={<DeckApproval />} />
                      </Route>
                      <Route path="cancel" element={<Cancel />}>
                        <Route path=":docId" element={<Cancel />} />
                      </Route>
                      <Route path="re-invite" element={<SecondInvitation />}>
                        <Route path=":docId" element={<SecondInvitation />} />
                      </Route>
                      <Route path="notme" element={<NotMe/>}>
                        <Route path=":email" element={<NotMe/>}/>
                      </Route>
                      <Route
                        path="join"
                        element={<InvitedInvitation />}
                      >
                        <Route path=":docId" element={<InvitedInvitation />}/>
                      </Route>
                      <Route path="approve" element={<Approve />}>
                        <Route path=":docId" element={<Approve />} />
                      </Route>
                      <Route path="seeker" element={<Seeker />}>
                        <Route path=":docId" element={<Seeker />} />
                      </Route>
                      <Route path="seekerreject" element={<SeekerReject />}>
                        <Route path=":docId" element={<SeekerReject />} />
                      </Route>
                      <Route
                        path="startupnotifications"
                        element={<InvestorInterest />}
                      >
                        <Route
                          path=":docId/:invId"
                          element={<InvestorInterest />}
                        />
                      </Route>
                      <Route
                        path="investorcontact"
                        element={<InvestorInterestContact />}
                      >
                        <Route
                          path=":investorId"
                          element={<InvestorInterestContact />}
                        />
                      </Route>
                      <Route path="notifications" element={<NewMatch />}>
                        <Route path=":docId" element={<NewMatch />} />
                      </Route>
                      <Route path="reject" element={<Reject />}>
                        <Route path=":docId" element={<Reject />} />
                      </Route>
                      <Route path="pitch" element={<Pitch/>}>
                      <Route path=":userId" element={<Pitch/>}/>
                      </Route>
                      <Route path="startup" element={<SharedMatch />}>
                        <Route path=":startupId/:potentialId" />
                      </Route>
                        <Route path="investorad" element={<InvestorAd/>}>
                          <Route path=":docId" element={<InvestorAd/>}/>
                        </Route>
                        <Route
                          path="startupregistration"
                          element={<StartupRegistration />}
                        >
                          <Route index element={<Company />} />
                          <Route path="company" element={<Company />} />
                          <Route path="industry" element={<Industry />} />
                          <Route path="founders" element={<Founders />} />
                          <Route path="ownership" element={<Ownership />} />
                          <Route path="support" element={<Support />} />
                          <Route path="matching" element={<Matching />} />
                        </Route>
                        <Route
                          path="investorregistration"
                          element={<InvestorRegistration />}
                        >
                          <Route index element={<InvestorCompany />} />
                          <Route path="company" element={<InvestorCompany />} />
                          <Route
                            path="industry"
                            element={<InvestorIndustry />}
                          />
                          <Route
                            path="founders"
                            element={<InvestorFounders />}
                          />
                          <Route
                            path="ownership"
                            element={<InvestorOwnership />}
                          />
                          <Route path="support" element={<InvestorSupport />} />
                          <Route
                            path="matching"
                            element={<InvestorMatching />}
                          />
                        </Route>

                      <Route
                        path="startupwelcome"
                        element={<StartupWelcome />}
                      />{" "}
                                            <Route
                        path="investorexpress"
                        element={<ExpressSignUp />}
                      />{" "}
                      <Route
                        path="investorwelcome"
                        element={<InvestorWelcome />}
                      />
                      <Route
                        path="bestresults"
                        element={<BestResults />}
                      />
                       <Route
                        path="expressmatching"
                        element={<ExpressMatching />}
                      />
                                            <Route
                        path="startuphome"
                        element={<StartupHome/>}
                      />
                       <Route
                        path="investorhome"
                        element={<InvestorHome/>}
                      />
                      <Route path="settings" element={<Settings />} />
                      <Route
                        path="notificationsettings"
                        element={<Notifications />}
                      />
                      <Route path="termsofservice" element={<Terms />}></Route>
                      <Route path="landing" element={<Landing />}></Route>
                      <Route path="startups/howitworks" element={<LandingAd />}></Route>
                      <Route path="getfunded" element={<AdLanding />}></Route>
                      <Route path="getfunded/mobile" element={<AdLandingMobile />}></Route>
                      <Route
                        path="investorinterest"
                        element={<InvestorInterest />}
                      ></Route>
                      <Route
                        path="investorinterestcontact"
                        element={<InvestorInterestContact />}
                      ></Route>
                      <Route path="thatsall" element={<ThatsAll />}></Route>
                      <Route
                        path="consentscreenshot"
                        element={<TwilioConsent />}
                      ></Route>
                      <Route path="dashboard" element={<Dashboard1 key={dashboardKey} />}></Route>
                      <Route path="buildastartup" element={<Build />}></Route>
                      <Route
                        path="startupdashboard"
                        element={<StartupDashboard />}
                      ></Route>
                      <Route path="admindashboard" element={<Admin />}></Route>
                      <Route path="users" element={<Users />}></Route>
                      <Route path="nearmatches" element={<Near />}></Route>
                      <Route
                        path="codegenerator"
                        element={<CodeGenerator />}
                      ></Route>
                      <Route
                        path="dataprofile/startup"
                        element={<DataProfileStartup />}
                      ></Route>
                      <Route
                        path="dataprofile/investor"
                        element={<DataProfileInvestor />}
                      ></Route>
                      <Route path="otp" element={<Otp />}></Route>
 
                      <Route
                        path="startupnomatchdashboard"
                        element={<StartupNomatchDashboard />}
                      ></Route>
                      {/* <Route path="dashboardnavbar" element={<DashboardNavbar />}></Route>*/}
                      <Route
                        path="passwordreset"
                        element={<PasswordReset />}
                      ></Route>
                      <Route path="pitchdeck" element={<Pitchdeck />}></Route>
                      <Route path="stats" element={<Stats />}></Route>
                      <Route path="matchpause" element={<MatchPause />}></Route>
                      <Route
                        path="pdf"
                        element={<MatchExample />}
                      ></Route>
                      <Route path="reactivate" element={<Reactivate />}></Route>
                      <Route path="remove" element={<Remove />}></Route>
                      <Route path="404" element={<NotFound404 />}></Route>
                      
                      <Route
                        path="removeconfirmation"
                        element={<RemoveConfirmation />}
                      ></Route>
                    </Route>
                  </Routes>
                </div>
              </div>
              <Footer style={{ zIndex: "12" }} />
            </div>
          </navBarContext.Provider>
        </Router>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
