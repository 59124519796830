import React, { useState, useContext, useEffect } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GreenTick from "../assets/greentick.png";
import { authContext, navBarContext } from "./context/context";
import { useMediaQuery } from "react-responsive";
import {
  auth,
  updateDoc,
  doc,
  db,
  signInWithEmailAndPassword,
  query,
  collection,
  Timestamp,
  where,
  getDocs,
} from "../firebase/firebaseApp";
import { padding } from "@mui/system";
import { updateLatestActions, sendBugEmails } from "../utils";
import {  BugTab } from "@peterfosso/prairi-components";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";

const Login = () => {
  const { currentUser } = useContext(authContext);
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  const { navBarHeight } = useContext(navBarContext);
  const time = Timestamp.fromDate(new Date());
  const [data, setData] = useState({
    email: "",
    password: "",
    remember: true,
    error: "",
    loading: false,
    message: "",
  });

   const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
    const [bugEmail, setBugEmail] = useState("");
    const [bugText, setBugText] = useState(null);
      const [bugError, setBugError] = useState(null)
  
  
      const handleUpload = (e) => {
        return new Promise((resolve, reject) => {
          const selectedFile = e.target.files[0];
    
          const storageRef = ref(storage, `bugs/${selectedFile.name}`);
          const uploadTask = uploadBytesResumable(storageRef, selectedFile);
    
          uploadTask.on(
            "state_changed",
            null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
            (error) => { // Manejador de error
              console.error("Upload failed:", error);
              reject(error);
            },
            async () => { // Manejador de éxito
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
              console.log("uploaded correctly");
              setScreenshotUrl(downloadURL);
            }
          );
          
        });
      };
    
      const handleBugText = (e) => {
        const value = e.target.value;
        setBugText(value);
        if (value !== "") {
          setBugError("");
        }
      };
      
      const handleBugEmail = (e) => {
        const value = e.target.value;
        setBugEmail(value);
        if (value !== "") {
          setBugError("");
        }
      };
      
      const validateFields = () => {
        if (!bugText || bugText.trim() === "") {
          setBugError("Please describe the bug.");
          return false;
        }
        if (!bugEmail || bugEmail.trim() === "") {
          setBugError("We need your email address.");
          return false;
        }
        return true;
      };
      
    const handleUploadAndSendEmail = async (file) => {
      try {
        if (!validateFields()) return;
  
        let uploadedUrl = screenshotUrl;
        if (file) {
          uploadedUrl = await handleUpload({ target: { files: [file] } });
        }
  
        console.log(`screenshotUrl ${uploadedUrl}`);
        sendBugEmails(bugEmail, "", bugText, "Build", uploadedUrl || "");
      } catch (error) {
        console.error("Error uploading file or sending email:", error);
      }
    };

  const [showPassword, setShowPassword] = useState(false);
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleForgotPassword = () => {
    // Implement the forgot password functionality here
    // console.log("Forgot Password clicked");
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      return { userData, userId };
    } catch (error) {
      // console.log(error);
    }
  };

  const handleNavigate = async () => {
    if (currentUser) {
      const { userData, userId } = await getDataFromDb();
      if (userData) {
        const userRef = doc(db, "users", userId);

        if (userData.category === "investor") {
          if (userData.agreed || userData.country === "United States") {
            if (userData.pendingMatches?.length > 0) {
              navigate(`/notifications/${userId}`);
            } else {
              navigate("/dashboard");
            }
          } else {
            navigate("/investorwelcome");
          }
        } else if (userData.category === "startup") {
          navigate("/startupdashboard");
        } else {
          navigate("/startupwelcome");
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setData({ ...data, error: "All fields are required." });
    } else {
      setData({
        ...data,
        error: "",
        loading: true,
        message: "Logging in...",
      });
      try {
        const result = await signInWithEmailAndPassword(auth, email, password);
        if (result.user) {
          // User has successfully logged in
          const { uid } = result.user;
          const userRef = doc(db, "users", uid);

          // Update isOnline field in the user document
          // await updateDoc(userRef, {
          //   isOnline: true,
          // });

          setData({
            email: "",
            password: "",
            error: "",
            loading: false,
            message: "Log in successful",
          });
          // Redirect user to dashboard
          handleNavigate();
          //updateLatestActions(userRef, "Logged in", isMobile);
          return; // Exit the function
        }
        throw new Error("User data missing after log in.");
      } catch (err) {
        // Handle login errors
        setData({
          ...data,
          error: err.message,
          loading: false,
          message: "",
        });
      }
    }
  };

  useEffect(() => {
    handleNavigate();
  }, [currentUser, location.state?.from, navigate]);

  const { email, error, loading, message, password, remember } = data;
  return (
    <div
      id="login__div"
      style={{
        marginTop: navBarHeight - 15,
        marginBottom: navBarHeight + 10,
        width: "100%",
        minWidth: "366px",
        padding: "2%",
      }}
    >
            <BugTab
                      email={bugEmail}
                      
                      setEmail={handleBugEmail}
                      setText={handleBugText}
                      submit={(file) =>
                        handleUploadAndSendEmail(file)
                      }
                      error={bugError}
                      handleFileChange={handleUpload}
                    />
      <section
        id="login__section"
        className={` 
        w-full max-w-[654px]
     
        shadow-black m-auto rounded-md
        py-2.5 px-2 shadow-md `}
        style={{
          marginTop: navBarHeight + 4,
          backgroundColor: "#1e1e1e",
          boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
          borderRadius: "20px",
        }}
      >
        <h1 className="mb-4 text-center text-xl text-gray-500 uppercase">
          Log in
        </h1>
        <h3 className="mb-2 text-center text-lg text-white">Welcome Back!</h3>
        <form
          className=" py-0 px-5"
          onSubmit={handleSubmit}
          data-testid="login-"
        >
          <div id="login__fieldEmail" className="mt-5">
            <label htmlFor="email">Email</label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="email"
              name="email"
              id="email"
              placeholder="Email Address"
              data-testid="login-email"
              value={email}
              onChange={handleChange}
            ></input>
          </div>
          <div id="login__fieldPassword" className="mt-5">
            <label htmlFor="password">Password</label>
            <div className="flex items-center justify-between border-blackish bg-white mt-2.5 w-full rounded border p-2.5 text-black ">
              <input
                className=" outline-none w-10/12 md:w-11/12"
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Enter password"
                value={password}
                onChange={handleChange}
                data-testid="login-password"
                autoComplete="on"
              ></input>
              <div id="login__togglePw" className="text-2xl">
                {showPassword ? (
                  <AiFillEye onClick={togglePassword} />
                ) : (
                  <AiFillEyeInvisible onClick={togglePassword} />
                )}
              </div>
            </div>
          </div>
          {error && <p className="my-2 text-center text-red-500">{error}</p>}
          {message && (
            <p className="my-2 text-center text-green-500">{message}</p>
          )}
          <div
            id="login__sectionTwo"
            className="w-full flex justify-between items-center my-4 text-sm"
          >
            <div>
              <label className="flex items-center  cursor-pointer ">
                <input
                  className="hidden"
                  type="checkbox"
                  id="remember"
                  value={remember}
                  name="remember"
                  onChange={handleChange}
                  data-testid="login-remember"
                ></input>
                <div className="w-4 h-4  relative border border-white overflow-visible">
                  {remember ? (
                    <div className="absolute -top-2  z-10 w-6  text-green-700 text-xl">
                      <img src={GreenTick} alt="check" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="ml-2 text-sm">Stay logged in</div>
              </label>
            </div>
            <div>
              <Link to="/ForgotPassword">
                <p
                  className="flex items-center  cursor-pointer "
                  onClick={handleForgotPassword}
                >
                  Forgot Password?
                </p>
              </Link>
            </div>
          </div>
          <div className=" my-2.5 mx-0 text-center">
            <input
              type="submit"
              data-testid="login-button"
              disabled={loading}
              className=" 
              uppercase bg-[#16a34a24] w-full 
              my-2 cursor-pointer rounded 
              border border-green-500 p-2.5 
              text-base text-white outline-none 
              transition-all duration-300 ease-in-out 
              hover:scale-105 hover:bg-green-600 hover:border-white "
              value="Log in"
            ></input>
          </div>
        </form>
        <div className="my-4">
          <h3 className="mt-6 text-center text-base text-white">
            Not a member?{" "}
            <div className="px-4 flex flex-col items-center  lg:mr-0">
              <Link
                className="text-blue-500 py-4 overflow-visible  flex justify-center"
                to="/invitation/stripe"
              >
                <span className="underline underline-offset-4">
                  Request to join PRAIRI as a{" "}
                  <span className="font-semibold "> startup</span>
                </span>
              </Link>
              <Link
                className="text-blue-500 underline underline-offset-4 overflow-visible flex justify-center"
                to="/invitation/investor"
              >
                <span>
                  Request to join PRAIRI as an{" "}
                  <span className="font-semibold "> investor</span>
                </span>
              </Link>
            </div>
          </h3>
        </div>
      </section>
    </div>
  );
};

export default Login;
