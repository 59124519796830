import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SettingsIcon from "../assets/settings.png";
import { useMediaQuery } from "react-responsive";
import { updateLatestActions, sendBugEmails } from "../utils";
import { navBarContext, authContext } from "./context/context";
import { PrairiButton, Loading, BugTab } from "@peterfosso/prairi-components";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Timestamp } from "../firebase/firebaseApp";
import { query, collection, db, where, getDocs, getDoc, doc, updateDoc } from "../firebase/firebaseApp";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Logo from "../assets/prairiwhite.png";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";



const MatchPause = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  let navigate = useNavigate();
  const [confirmed, setConfirmed] = useState(false);
  const [confirmUndo, setConfirmUndo] = useState(false);
  const [docId, setDocId] = useState(null);
  const [buttonText, setButtonText] = useState("Pause data profile");
  const [reason, setReason] = useState(null)
  const [otherReason, setOtherReason] = useState(null)
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [currentUserData, setCurrentUserData] = useState({})
  const [dataFetched, setDataFetched] = useState(false)
      const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
      const [bugEmail, setBugEmail] = useState("");
      const [bugText, setBugText] = useState("");
        const [bugError, setBugError] = useState(null)
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const time = Timestamp.fromDate(new Date())
  const latestTime = time.toDate().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
  // console.log("npm start");
  //console.log(docId);

  const redirectToLogin = () => {
    navigate("/login"); // Redirect to the login page
  };




  const fetchData = async () => {
    try {
      if (!currentUser) return; // Asegúrate de que currentUser está disponible
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        setCurrentUserData(doc.data());
        setDocId(doc.id);
        setDataFetched(true);
      } else {
        console.error("Usuario no encontrado en Firestore.");
      }
    } catch (error) {
      console.error("Error al obtener datos del usuario:", error);
    }
  };
  
  useEffect(() => {
    if (!currentUser) redirectToLogin();
    fetchData();
  }, [currentUser]);
  

  const handleUpload = (e) => {
      return new Promise((resolve, reject) => {
        const selectedFile = e.target.files[0];
  
        const storageRef = ref(storage, `bugs/${selectedFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, selectedFile);
  
        uploadTask.on(
          "state_changed",
          null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
          (error) => { // Manejador de error
            console.error("Upload failed:", error);
            reject(error);
          },
          async () => { // Manejador de éxito
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
            console.log("uploaded correctly");
            setScreenshotUrl(downloadURL);
          }
        );
        
      });
    };
  
    const handleBugText = (e) => {
      const value = e.target.value;
      setBugText(value);
      if (value !== "") {
        setBugError("");
      }
    };
    
    const handleBugEmail = (e) => {
      const value = e.target.value;
      setBugEmail(value);
      if (value !== "") {
        setBugError("");
      }
    };
    
    const validateFields = () => {
      if (!bugText || bugText.trim() === "") {
        setBugError("Please describe the bug.");
        return false;
      }
      return true;
    };
    
    const handleUploadAndSendEmail = async (file) => {
      try {
        if (!validateFields()) return;
    
        let uploadedUrl = screenshotUrl;
        if (file) {
          uploadedUrl = await handleUpload({ target: { files: [file] } });
        }

        console.log(`screenshotUrl ${uploadedUrl}`);

        sendBugEmails(
          currentUserData.email,
          currentUserData.contactName,
          bugText,
          "Pause",
          uploadedUrl || ""
        );
      } catch (error) {
        console.error("Error uploading file or sending email:", error);
      }
    };
  
  

  const closePopupsPause = () => {
    setConfirmUndo(false);
    setConfirmed(false);
    navigate("/reactivate")
  }

  const closePopupsUndo = () => {
    setConfirmUndo(false);
    setConfirmed(false);
  }

  const handlePause = async (e) => {
    try {
      // Get user data from email
      const { uid } = currentUser;
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userId;
      let userPaused;
      querySnapshot.forEach((doc) => {
        userId = doc.id;
        userPaused = doc.data().paused
      });
      //console.log("paused? " + userPaused)

      const userRef = doc(db, "users", userId);
      if (reason === null) {
        setShowErrorMessage(true);
        return;
      }
      else {
        if (reason !== "other") {
          await updateDoc(userRef, { paused: true, "paused.reason": reason, "paused.time": time }); // update the value of the `category` field
        }
        else {
          await updateDoc(userRef, { paused: true, "paused.reason": otherReason, "paused.time": time});
        }
      }
      const updatedDoc = await getDoc(userRef);
      updateLatestActions(userRef, "Paused profile", isMobile)
      userPaused = updatedDoc.data().paused; // update the value of `userPaused`
      //console.log("logging handlePause")
      // console.log("userId: " + userId)
      //console.log("paused? " + userPaused)

      setConfirmed(true)

      return;

    } catch (error) {
      // console.log(error);
    }
  };
  const handleRemoveProfile = () => {
    navigate('/remove');
  };
  const handleUnpause = async (e) => {
    try {
      // Get user data from email
      const { uid } = currentUser;
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userId;
      let userPaused;
      querySnapshot.forEach((doc) => {
        userId = doc.id;
        userPaused = doc.data().paused
      });
      //console.log("paused? " + userPaused)
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, { paused: false }); // update the value of the `category` field
      updateLatestActions(userRef, "Reactivated profile", isMobile)
      const updatedDoc = await getDoc(userRef);
      userPaused = updatedDoc.data().paused; // update the value of `userPaused`
      //console.log("logging handleUnpause")
      //console.log("userId: " + userId)
      //console.log("paused? " + userPaused)
      setConfirmUndo(true)
      setConfirmed(false)
      setButtonText("Pause data profile")
      return;

    } catch (error) {
      // console.log(error);
    }
  }

  const WhiteRadio = withStyles({
    root: {
      color: "white",
      "&$checked": {
        color: "#92d050",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);


if (!dataFetched || !currentUser){
  return (<div className={`md:max-w-2xl md:mx-auto  from-navStart to-navEnd px-4`}
  style={{
    marginTop: navBarHeight + 30,

  }}><Loading/></div>)
}


  return (


    <div
      className={`md:max-w-2xl md:mx-auto  from-navStart to-navEnd px-4`}
      style={{
        marginTop: navBarHeight + 12, backgroundColor: "#1e1e1e",
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
        borderRadius: "20px"
      }}
    >
                           {currentUserData && (
                             <BugTab
                               email={currentUserData.email}
                               name={currentUserData.contactName}
                               setEmail={handleBugEmail}
                               setText={handleBugText}
                               submit={(file) =>
                                 handleUploadAndSendEmail(file)
                               }
                               error={bugError}
                               handleFileChange={handleUpload}
                             />
                           )}

      {dataFetched && currentUserData.category === "startup" &&
        <div className="flex flex-col items-center w-full my-4 text-customWhite text-lg ">
                <div className="w-3/12 mx-auto mt-8 mb-4">
        <img className="w-full" src={SettingsIcon} alt="Settings icon" />
      </div>
      <h1 style={{ color: "white" }} className="font-bold text-center w-full text-black text-xl my-4" >
        Pause your match profile{" "}
      </h1>
      <h2 className="text-left w-full text-customWhite text-lg mt-4 mb-2" >
        Reason for pausing my Data Profile:
      </h2>
      
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              value={reason}
              onChange={(event) => setReason(event.target.value)}
              name="radio-buttons-group"
              className={`pt-3`}
              required
            >
              <FormControlLabel
                value="Found funding on Prairi"
                control={<WhiteRadio />}
                label={
                  <span>
                    We just received our funding on PRAIRI
                  </span>
                }
              />
              <FormControlLabel
                value="Found funding NOT on Prairi"
                control={<WhiteRadio />}
                label={
                  <span>
                    We received our funding (but not on PRAIRI)
                  </span>
                }
              />
              <FormControlLabel
                value="Not pursuing investment"
                control={<WhiteRadio />}
                label={
                  <span>
                    We've decided not to pursue investment at this time
                  </span>
                }
              />
              <FormControlLabel
                value="other"
                control={<WhiteRadio />}
                label={
                  <div className="">
                    Other reason:

                  </div>
                }
              />
            </RadioGroup>
            <input onChange={(event) => setOtherReason(event.target.value)} className="rounded-md border-blackish flex-3 text-black ml-5 border-solid  border-2  border-color: yellowPrairi w-64" type="textarea" />
          </FormControl>
          {showErrorMessage && <p className="text-redPrairi">Please select a reason for pausing.</p>}

          <div className="my-2 mt-8 flex items-center justify-between">
            {/* <div className="my-2  grid grid-cols-2 gap-3 justify-between"></div> */}


            <PrairiButton click={handlePause} label="Pause Data Profile" size="lg" border="thinYellow" rounded="soft" bgColor="darker"/>

            <PrairiButton click={()=> navigate("/settings")} label="Back" size="xs" border="no-border" bgColor="lighter" rounded="soft"/>
          </div>
          <button className="flex md:jusify-items-end lg:justify-evenly p-3 underline " onClick={handleRemoveProfile} >Remove Profile</button>
        </div>
      }

      {dataFetched && currentUserData.category === "investor" &&
        <div className="flex flex-col items-center w-full my-4 min-w-[400px] text-customWhite text-lg ">
                <div className="w-3/12 mx-auto mt-8 mb-4">
        <img className="w-full" src={SettingsIcon} alt="Settings icon" />
      </div>
      <h1 style={{ color: "white" }} className="font-bold text-center w-full text-black text-xl my-4" >
        Pause your match profile{" "}
      </h1>
      <h2 className=" text-center w-full text-customWhite text-lg mt-4 mb-2" >
        Reason for pausing my Data Profile:
      </h2>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              value={reason}
              onChange={(event) => setReason(event.target.value)}
              name="radio-buttons-group"
              className={`pt-3`}
              required
            >
              <FormControlLabel
                value="Getting too many notifications"
                control={<WhiteRadio />}
                label={
                  <span>
                    Getting too many notifications
                  </span>
                }
              />
              <FormControlLabel
                value="Not enough quality matches"
                control={<WhiteRadio />}
                label={
                  <span>
                    Not enough quality matches
                  </span>
                }
              />
              <FormControlLabel
                value="Too many matches"
                control={<WhiteRadio />}
                label={
                  <span>
                    Too many matches
                  </span>
                }
              />
              <FormControlLabel
                value="other"
                control={<WhiteRadio />}
                label={
                  <span>
                    Other reason:
                 
                  </span>
                }
              />
            </RadioGroup>
            <input onChange={(event) => setOtherReason(event.target.value)} className="rounded-md border-blackish flex-3 text-black ml-5 border-solid  border-2  border-color: yellowPrairi w-64" type="textarea" />
          </FormControl>
          {showErrorMessage && <p className="text-redPrairi">Please select a reason for pausing.</p>}

          <div className="my-2 mt-8 flex items-center justify-between">
            {/* <div className="my-2  grid grid-cols-2 gap-3 justify-between"></div> */}


            <PrairiButton click={handlePause} label="Pause Data Profile" size="lg" border="thinYellow" rounded="soft" bgColor="darker"/>

            <PrairiButton click={()=> navigate("/settings")} label="Back" size="xs" border="no-border" bgColor="lighter" rounded="soft"/>
          </div>
          <button className="flex md:jusify-items-end lg:justify-evenly p-3 underline " onClick={handleRemoveProfile} >Remove Profile</button>
        </div>
      }



      <Modal
        open={confirmed}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="min-w-[250px] min-h-[270px]
                max-w-[300px]"
          sx={{
            background: "#1e1e1d",
            color: "#f2f2f2",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            maxWidth: "450px",
            maxHeight: "350px",
            height: isMobile?"60%":"40%",
            borderRadius: "20px",
            padding: "10px 17px 2px 15px",
            opacity: "1",
            fontSize: "16px"
          }}

        ><img src={Logo} className={`${isMobile?"w-[30%]":"w-1/6"} h-auto mx-auto mb-5`} alt="unicorn-logo"></img>

          <p className="mb-2 text-center">
            Your profile has been paused successfully.
          </p>
          <p className="text-center">
            We’ll check back with you from time to time to see when you’re ready to come back!
          </p>
          <div>
      <div className="flex justify-center mt-5 items-between">
        <PrairiButton bgColor="lighter" label="OK" click={closePopupsPause} size="md" rounded="soft" />
        <button
          className="font-[sans-serif] text-[#f2f2f2] underline rounded-full w-[4rem]"
          onClick={handleUnpause}
        >
          <p>undo</p>
        </button>
      </div>
      <div className="flex justify-center"></div>
    </div>

          <div
            className="
                  flex 
                  justify-end 
                  items-center 
                  align-center
                  mt-4
                  w-1/2
                  mx-auto"
          >
          </div>
        </Box>
      </Modal>

      <Modal
        open={confirmUndo}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="min-w-[150px] min-h-[100px]
                max-w-[300px]"
          sx={{
            background: "#1e1e1d",
            color: "#f2f2f2",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile?"70%":"25%",
            maxWidth: "450px",
            maxHeight: "300px",
            height: "29%",
            borderRadius: "20px",
            padding: "10px 17px 2px 15px",
            opacity: "1",
            fontSize: "16px"
          }}

        ><img src={Logo} className={`${isMobile?"w-1/4":"w-1/6"} h-auto mx-auto mb-2`} alt=""></img>

          <p className="my-2 text-center">
            Ok!
          </p>
          <p className="text-center mb-2">
            Your profile is active again.
          </p>

          <div className="flex justify-center ">

            <PrairiButton label="OK" border="silver" size="sm" rounded="soft" click={()=>closePopupsUndo()}/>
          </div>

          <div
            className="
                  flex 
                  justify-end 
                  items-center 
                  align-center
                  mt-4
                  w-1/2
                  mx-auto"
          >
          </div>
        </Box>
      </Modal>
      {/*Add pause reason */}

    </div>
  );
};


export default MatchPause;

