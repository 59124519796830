import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import { usStates } from "../countries.js";
import axios from "axios";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import CurrencyFormat from "react-currency-format";
import {
  BugTab,
  Modal,
  PrairiButton,
  SaveReminder,
} from "@peterfosso/prairi-components";
import { navBarContext, authContext } from "./context/context";
import { useMediaQuery } from "react-responsive";
import {
  query,
  collection,
  db,
  where,
  updateDoc,
  doc,
  getDocs,
} from "../firebase/firebaseApp";
import { sendBugEmails } from "../utils.js";
import Industries from "../assets/industry.png";
import Model from "../assets/biz_model.gif";
import Stage from "../assets/stage.gif";
import Investment from "../assets/amount.gif";
import Location from "../assets/companyLocation.png"

const ExpressSignUp = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const didMount = useRef(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  let navigate = useNavigate();
  let location = useLocation();
  const [errorPopup, setErrorPopup] = useState(false);
  const [userFormData, setUserFormData] = useState({});
  const [userData, setUserData] = useState(null);
  const [savePopup, setSavePopup] = useState(false);
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState("");
  const [bugError, setBugError] = useState(null);
  const [currency, setCurrency] = useState("EUR");
  const [isAmplified, setIsAmplified] = useState(false);
  const [amount, setAmount] = useState([3000000, 6000000]); //this is the one we're saving
  const [data, setData] = useState({
    amount: [3000000, 6000000],
    stage: {
        "Early/Seed": true,
        "Growth": true,
        "Series A": true,
        "Series B/Higher": true
    },
    currency: "EUR",
    model: {B2B: true, B2C: true},
    location: "ANYWHERE",
    type: {
        "Software": true,
        "Hardware": true,
        "SaaS": true,
        "Other": true
    }
  })

  const currencySelectOptions = ["EUR", "USD", "GBP", "SEK"];

  const toggleAmplify = () => {
    setIsAmplified(!isAmplified);
  };

  const rates = {
    USD: {
      EUR: 0.95,
      SEK: 10.91,
      GBP: 0.78,
    },
    EUR: {
      USD: 1.05,
      SEK: 11.46,
      GBP: 0.82,
    },
    SEK: {
      USD: 0.09,
      EUR: 0.09,
      GBP: 0.07,
    },
    GBP: {
      USD: 1.26,
      EUR: 1.2,
      SEK: 13.82,
    },
  };

  const convertCurrency = (amount, fromCurrency, toCurrency) => {
    if (fromCurrency === toCurrency) {
      return amount;
    }

    const rate = rates[fromCurrency][toCurrency];
    return [Math.ceil(amount[0] * rate), Math.ceil(amount[1] * rate)];
  };

  useEffect(()=> {
    let newAmount = convertCurrency(data.amount, data.currency, currency)
    if (data.currency === "SEK" && !isAmplified) {
        if (newAmount[0] < 1000000) {
          newAmount[0] = 1000000;
        }
        if (newAmount[1] > 100000000) {
          newAmount[1] = 100000000;
        }
      } else {
        if (newAmount[0] < 100000 && !isAmplified) {
          newAmount[0] = 100000;
        }
        if (newAmount[1] > 10000000 && !isAmplified) {
          newAmount[1] = 10000000;
        }
      }
    setAmount(newAmount)
  }, [currency])
  
  const handleUpload = (e) => {
    return new Promise((resolve, reject) => {
      const selectedFile = e.target.files[0];

      const storageRef = ref(storage, `bugs/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
        (error) => {
          // Manejador de error
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => {
          // Manejador de éxito
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
          console.log("uploaded correctly");
          setScreenshotUrl(downloadURL);
        }
      );
    });
  };

  const handleBugText = (e) => {
    const value = e.target.value;
    setBugText(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const handleBugEmail = (e) => {
    const value = e.target.value;
    setBugEmail(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const validateFields = () => {
    if (!bugText || bugText.trim() === "") {
      setBugError("Please describe the bug.");
      return false;
    }
    return true;
  };

  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;

      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUpload({ target: { files: [file] } });
      }

      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(
        userData.email,
        userData.contactName,
        bugText,
        "Investor express",
        uploadedUrl || ""
      );
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="w-full px-0.5 bg-rainbowBg mb-8"
      style={{ paddingTop: navBarHeight }}
    >
      <BugTab
        email={bugEmail}
        setEmail={handleBugEmail}
        setText={handleBugText}
        submit={(file) => handleUploadAndSendEmail(file)}
        error={bugError}
        handleFileChange={handleUpload}
      />

      {
        <div
          className={` w-full bg-body flex flex-col items-center justify-center pt-2 pb-24`}
        >
          <h1 className="my-2 md:my-6 text-center  text-base text-xl  tracking-wide w-full text-gray-500 uppercase">
            Your Match Preferences
          </h1>
          <div className="flex flex-col w-full justify-center items-center">
            <div className="flex flex-col w-full justify-center items-center">
              {/* INDUSTRIES */}
              <div
                className={`flex items-center ${
                  isMobile ? "w-full" : "w-1/3"
                } my-4 px-2`}
              >
                <img
                  src={Industries}
                  className="w-[50px] h-[50px] mr-5"
                  alt="Industries icon"
                />
                <div className="flex flex-col w-full">
                  <div className="font-bold text-xl mb-2">INDUSTRIES</div>
                  <div className="flex items-center justify-start">
                    <div className="bg-greenPrairi w-[15px] h-[15px] rounded-full" />
                    <div className="mx-4">Any industry</div>
                    <div className="ml-2 text-silverPrairi underline cursor-pointer">
                      Change
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`flex items-start ${
                  isMobile ? "w-full" : "w-1/3"
                } my-4 px-2`}
              >
                <img
                  src={Model}
                  className="w-[50px] h-[50px] mr-5"
                  alt="Industries icon"
                />
                <div>
                  <div className="flex flex-col w-full mb-8">
                    <div className="font-bold text-xl mb-2">
                      BUSINESS MODEL PREFERENCE
                    </div>
                    <div className="flex items-center justify-start">
                      <div className="bg-greenPrairi w-[15px] h-[15px] rounded-full" />
                      <div className="mx-4">Any (B2B or B2C)</div>
                      <div className="ml-2 text-silverPrairi underline cursor-pointer">
                        Change
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full mt-4">
                    <div className="font-bold text-xl mb-2">
                      PRODUCT FOCUS PREFERENCE
                    </div>
                    <div className="flex items-center justify-start">
                      <div className="bg-greenPrairi w-[15px] h-[15px] rounded-full" />
                      <div className="mx-4">Any </div>
                      <div className="ml-2 text-silverPrairi underline cursor-pointer">
                        Change
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* STAGES */}
              <div
                className={`flex items-center ${
                  isMobile ? "w-full" : "w-1/3"
                } my-4 px-2`}
              >
                <img
                  src={Stage}
                  className="w-[50px] h-[50px] mr-5"
                  alt="Stages icon"
                />
                <div className="flex flex-col w-full">
                  <div className="font-bold text-xl mb-2">STAGES</div>
                  <div className="flex items-center justify-start">
                    <div className="bg-greenPrairi w-[15px] h-[15px] rounded-full" />
                    <div className="mx-4">Any stage</div>
                    <div className="ml-2 text-silverPrairi underline cursor-pointer">
                      Change
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`flex items-start ${
                  isMobile ? "w-full" : "w-1/3"
                } my-4 px-2`}
              >
                <img
                  src={Investment}
                  className="w-[50px] h-[50px] mr-5"
                  alt="Stages icon"
                />
                <div className="flex flex-col w-full">
                  <div className="font-bold text-xl mb-2">INVESTMENT RANGE</div>
                  <div className="flex items-start flex-col">
                    <div className="inline-flex items-center bg-buttonParent mb-4 py-1 pl-2">
                      {currencySelectOptions.map((currentCurrency, index) => (
                        <input
                          key={index}
                          className={`uppercase mr-1 ${
                            currentCurrency === currency
                              ? "bg-[#303030] opacity-100 border border-[#00b0f0]"
                              : "bg-inactiveButton opacity-50"
                          } text-xs cursor-pointer rounded border-solid py-1 px-4 text-white outline-none`}
                          type="button"
                          value={currentCurrency}
                          name="currency"
                          onClick={() => setCurrency(currentCurrency)}
                        />
                      ))}
                    </div>

                    <div className="flex w-[95%] items-center">
                      {currency !== "SEK" && (
                        <div className="flex w-full items-center">
                          <Slider
                            range
                            step={isAmplified ? 1000000 : 100000}
                            min={isAmplified ? 1000000 : 100000}
                            max={isAmplified ? 100000000 : 10000000}
                            allowCross={false}
                            value={amount} // Vinculamos el slider al estado
                            onChange={(values) => setAmount(values)} // Actualizamos el estado al cambiar el rango
                            trackStyle={[
                              { backgroundColor: "#00b0f0", height: "6px" },
                            ]}
                            railStyle={{
                              backgroundColor: "grey",
                              height: "6px",
                            }}
                            handleStyle={[
                              {
                                height: "15px",
                                width: "15px",
                                borderColor: "grey",
                                boxShadow: "none",
                                borderWidth: "0px",
                                borderRadius: "2px",
                                backgroundColor: "grey",
                                outline: "none",
                                opacity: 1,
                              },
                              {
                                height: "15px",
                                width: "15px",
                                borderColor: "grey",
                                borderWidth: "0px",
                                borderRadius: "2px",
                                boxShadow: "none",
                                backgroundColor: "grey",
                                outline: "none",
                                opacity: 1,
                              },
                            ]}
                          />
                          <button
                            type="button"
                            onClick={toggleAmplify}
                            className={`text-xs p-1 ml-4 border rounded-md bg-[#303030] ${
                              isAmplified
                                ? "border-bluePrairi border-solid"
                                : "border-[#303030]"
                            }`}
                          >
                            +10x
                          </button>
                        </div>
                      )}
                                           {currency === "SEK" && (
                        <div className="flex w-full items-center">
                          <Slider
                            range
                            step={isAmplified ? 1000000 : 100000}
                            min={isAmplified ? 10000000 : 1000000}
                            max={isAmplified ? 1000000000 : 100000000}
                            allowCross={false}
                            value={amount} // Vinculamos el slider al estado
                            onChange={(values) => setAmount(values)} // Actualizamos el estado al cambiar el rango
                            trackStyle={[
                              { backgroundColor: "#00b0f0", height: "6px" },
                            ]}
                            railStyle={{
                              backgroundColor: "grey",
                              height: "6px",
                            }}
                            handleStyle={[
                              {
                                height: "15px",
                                width: "15px",
                                borderColor: "grey",
                                boxShadow: "none",
                                borderWidth: "0px",
                                borderRadius: "2px",
                                backgroundColor: "grey",
                                outline: "none",
                                opacity: 1,
                              },
                              {
                                height: "15px",
                                width: "15px",
                                borderColor: "grey",
                                borderWidth: "0px",
                                borderRadius: "2px",
                                boxShadow: "none",
                                backgroundColor: "grey",
                                outline: "none",
                                opacity: 1,
                              },
                            ]}
                          />
                          <button
                            type="button"
                            onClick={toggleAmplify}
                            className={`text-xs p-1 ml-4 border rounded-md bg-[#303030] ${
                              isAmplified
                                ? "border-bluePrairi border-solid"
                                : "border-[#303030]"
                            }`}
                          >
                            +10x
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="ml-2 text-sm md:text-base font-bold flex items-center">
                      Range:{" "}
                      <CurrencyFormat
                        className="mx-1.5"
                        displayType="text"
                        thousandSeparator={true}
                        value={amount[0]}
                      />{" "}
                      -{" "}
                      <CurrencyFormat
                        className="ml-1.5"
                        displayType="text"
                        thousandSeparator={true}
                        value={amount[1]}
                      />
                      {/* {((data.formData.industry.amount[1] === 100000000 && data.formData.industry.currency === "SEK") ||
                      (data.formData.industry.amount[1] === 10000000 && data.formData.industry.currency !== "SEK")) && (
                      <span className="ml-0">+</span>
                    )} */}
                      <span className="ml-1.5 ">{currency.toUpperCase()}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`flex items-start ${
                  isMobile ? "w-full" : "w-1/3"
                } my-4 px-2`}
              >
                <img
                  src={Location}
                  className="w-[50px] h-[50px] mr-5"
                  alt="Stages icon"
                />
                <div className="flex flex-col w-full">
                  <div className="font-bold text-xl mb-2">LOCATION</div>
                  <div className="font-bold mb-2 text-sm">Where would you prefer the startups to be located?</div>
                  <div className="flex items-center justify-start">
                    <div className="bg-greenPrairi w-[15px] h-[15px] rounded-full" />
                    <div className="mx-4">Anywhere in the world</div>
                    <div className="ml-2 text-silverPrairi underline cursor-pointer">
                      Change
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5">
          <PrairiButton label="OK, looks good!" border="thinGreen" size="md" rounded="soft"/>
          </div>
        </div>
      }
    </div>
  );
};

export default ExpressSignUp;
