import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SettingsIcon from "../assets/settings.png";
import { navBarContext, authContext } from "./context/context";
import { PrairiButton, BugTab } from "@peterfosso/prairi-components";
import { updateLatestActions, sendBugEmails } from "../utils";
import { useMediaQuery } from "react-responsive";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  setDoc,
  getDoc,
  doc,
  updateDoc,
  Timestamp,
} from "../firebase/firebaseApp";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Logo from "../assets/prairiwhite.png";
import { QuerySnapshot } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";

const Reactivate = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  let navigate = useNavigate();
  const [confirmed, setConfirmed] = useState(false);
  const [confirmUndo, setConfirmUndo] = useState(false);
  const [docId, setDocId] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
        const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
        const [bugEmail, setBugEmail] = useState("");
        const [bugText, setBugText] = useState("");
          const [bugError, setBugError] = useState(null)
            const [currentUserData, setCurrentUserData] = useState({})

            useEffect(() => {
              if (currentUser) {
                setDocId(currentUser.uid);
                getDataFromDb();
              }
            }, [currentUser]);

            const getDataFromDb = async () => {
              try {
                const { email } = currentUser;
                const q = query(collection(db, "users"), where("email", "==", email));
                const querySnapshot = await getDocs(q);
            
                if (querySnapshot.empty) {
                  console.error("No user found with the given email");
                  return;
                }
            
                let userData;
                let userId;
                querySnapshot.forEach((doc) => {
                  userData = doc.data();
                  userId = doc.id;
                });
                setCurrentUserData(userData)
                return { userData, userId };
              } catch (error) {
                console.error("Error fetching user data:", error);
              }
            };
            

    const handleUpload = (e) => {
        return new Promise((resolve, reject) => {
          const selectedFile = e.target.files[0];
    
          const storageRef = ref(storage, `bugs/${selectedFile.name}`);
          const uploadTask = uploadBytesResumable(storageRef, selectedFile);
    
          uploadTask.on(
            "state_changed",
            null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
            (error) => { // Manejador de error
              console.error("Upload failed:", error);
              reject(error);
            },
            async () => { // Manejador de éxito
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
              console.log("uploaded correctly");
              setScreenshotUrl(downloadURL);
            }
          );
          
        });
      };
    
      const handleBugText = (e) => {
        const value = e.target.value;
        setBugText(value);
        if (value !== "") {
          setBugError("");
        }
      };
      
      const handleBugEmail = (e) => {
        const value = e.target.value;
        setBugEmail(value);
        if (value !== "") {
          setBugError("");
        }
      };
      
      const validateFields = () => {
        if (!bugText || bugText.trim() === "") {
          setBugError("Please describe the bug.");
          return false;
        }
        return true;
      };
      
      const handleUploadAndSendEmail = async (file) => {
        try {
          if (!validateFields()) return;
      
          let uploadedUrl = screenshotUrl;
          if (file) {
            uploadedUrl = await handleUpload({ target: { files: [file] } });
          }
  
          console.log(`screenshotUrl ${uploadedUrl}`);
  
          sendBugEmails(
            currentUserData.email,
            currentUserData.contactName,
            bugText,
            "Reactivate",
            uploadedUrl || ""
          );
        } catch (error) {
          console.error("Error uploading file or sending email:", error);
        }
      };
    
    

  const handleRedirection = async () => {
    const { userData } = await getDataFromDb();
    if (!userData.paused) {
      navigate("/matchpause");
    }
  };

  const closePopupsUnpause = () => {
    setConfirmUndo(false);
    setConfirmed(false);
    navigate("/matchpause");
  };

  const closePopupsUndo = () => {
    setConfirmUndo(false);
    setConfirmed(false);
  };

  const handlePause = async (e) => {
    try {
      // Get user data from email
      const { uid } = currentUser;
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userId;
      let userPaused;
      querySnapshot.forEach((doc) => {
        userId = doc.id;
        userPaused = doc.data().paused;
        // userReason = doc.data().paused.pauseReason
      });
      // console.log("paused? " + userPaused)
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, { paused: true }); // update the value of the `category` field
      const updatedDoc = await getDoc(userRef);
      userPaused = updatedDoc.data().paused; // update the value of `userPaused`
      // userReason = updateDoc.data().pauseReason
      //  console.log("logging handlePause")
      //  console.log("userId: " + userId)
      // console.log("paused? " + userPaused)
      setConfirmUndo(true);
      return;
    } catch (error) {
      // console.log(error);
    }
  };

  const handleUnpause = async (e) => {
    try {
      // Get user data from email
      const { uid } = currentUser;
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userId;
      let userPaused;
      querySnapshot.forEach((doc) => {
        userId = doc.id;
        userPaused = doc.data().paused;
      });
      // console.log("paused? " + userPaused)
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, { paused: false }); // update the value of the `category` field
      updateLatestActions(userRef, "Reactivated profile", isMobile);
      const updatedDoc = await getDoc(userRef);
      userPaused = updatedDoc.data().paused; // update the value of `userPaused`
      // console.log("logging handleUnpause")
      // console.log("userId: " + userId)
      //console.log("paused? " + userPaused)
      setConfirmed(true);
      return;
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <div
      className={`md:max-w-2xl md:mx-auto   from-navStart to-navEnd px-8`}
      style={{
        marginTop: navBarHeight + 24,
        backgroundColor: "#1e1e1e",
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
        borderRadius: "20px",
      }}
    >
                                 {currentUserData && (
                                   <BugTab
                                     email={currentUserData.email}
                                     name={currentUserData.contactName}
                                     setEmail={handleBugEmail}
                                     setText={handleBugText}
                                     submit={(file) =>
                                       handleUploadAndSendEmail(file)
                                     }
                                     error={bugError}
                                     handleFileChange={handleUpload}
                                   />
                                 )}
      <div className="w-3/12 mx-auto mt-8 mb-4">
        <img className="w-full" src={SettingsIcon} alt="Settings icon" />
      </div>
      <h1
        style={{ color: "white" }}
        className="font-bold text-center w-full text-black md:text-xl my-4"
      >
        Reactivate your match profile{" "}
      </h1>
      <div className="flex flex-col items-center w-full my-4 text-black md:text-lg ">
        <div className="my-2  flex items-center justify-between">
          {/* <div className="my-2  grid grid-cols-2 gap-3 justify-between"></div> */}


          <PrairiButton label="Reactivate Data Profile" size="lg" click={handleUnpause} rounded="soft" border="thinYellow" bgColor="lighter" textColor="whiteText" />

          <PrairiButton size="xs" border="no-border" bgColor="lighter" label="back" rounded="soft" textColor="whiteText" click={()=>navigate("/settings")}/>
        </div>
        <button
          className="flex md:jusify-items-end lg:justify-evenly text-customWhite p-3 underline"
          onClick={() => navigate("/remove")}
        >
          Remove Profile
        </button>
      </div>
      <Modal
        open={confirmed}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="min-w-[250px] min-h-[200px] max-w-[300px]"
          sx={{
            background: "#1e1e1d",
            color: "#f2f2f2",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            maxWidth: "450px",
            maxHeight: "250px", // Decreased maxHeight
            height: "45%", // Decreased height
            borderRadius: "50px",
            padding: "10px 17px 2px 15px",
            opacity: "1",
            fontSize: "16px",
          }}
        >
          <img src={Logo} className="w-1/6 h-auto mx-auto mb-2" alt="" />

          <p className=" text-center mt-5">
            Your profile has been reactivated successfully!
          </p>
          <div>
            <div className="flex justify-center items-between mt-5">
              <PrairiButton
                bgColor="lighter"
                label="OK"
                click={closePopupsUnpause}
                size="sm"
                rounded="soft"
              />
              <button
                className="font-[sans-serif] text-[#f2f2f2] underline rounded-full w-[4rem]"
                onClick={handlePause}
              >
                <p>undo</p>
              </button>
            </div>
            <div className="flex justify-center"></div>
          </div>
          <div className="flex justify-end items-center align-center mt-4 w-1/2 mx-auto"></div>
        </Box>
      </Modal>

      <Modal
        open={confirmUndo}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="min-w-[250px] min-h-[150px]
                max-w-[300px]"
          sx={{
            background: "#1e1e1d",
            color: "#f2f2f2",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "25%",
            maxWidth: "450px",
            maxHeight: "300px",
            height: "40%",
            borderRadius: "50px",
            padding: "10px 17px 2px 15px",
            opacity: "1",
            fontSize: "16px",
          }}
        >
          <img src={Logo} className="w-1/6 h-auto mx-auto mb-2" alt=""></img>

          <p className="mb-2 text-center">Ok!</p>
          <p className="text-center">Your profile is paused again.</p>

          <div className="flex justify-center ">
            <button
              className="py-1.5 px-3 text-lg mt-2 mb-2 text-center rounded-lg bg-customWhite text-backgroundPrairi border-2 border-lighterGray justify-end w-1/4"
              onClick={() => closePopupsUndo()}
            >
              OK
            </button>
          </div>

          <div
            className="
                  flex 
                  justify-end 
                  items-center 
                  align-center
                  mt-4
                  w-1/2
                  mx-auto"
          ></div>
        </Box>
      </Modal>
      {/*Add pause reason */}
    </div>
  );
};

export default Reactivate;
