import React, {useContext, useRef} from "react";
import { TextButton, PrairiButton } from "@peterfosso/prairi-components";
import { useNavigate } from "react-router-dom";
import { navBarContext, authContext } from "./context/context";
import Target from "../assets/exact_match.png"
import { useMediaQuery } from "react-responsive";

const BestResults = () => {
    const { navBarHeight } = useContext(navBarContext);
    const { currentUser } = useContext(authContext);
    let navigate = useNavigate();
    const containerRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });
    return (
        <div
        className="md:max-w-lg p-1 from-navStart to-navEnd px-4 flex justify-center items-center py-5 flex-col w-full"
        ref={containerRef}
        style={{ marginTop: navBarHeight + 4 }}
      >
        <img src={Target} alt="Target icon with an arrow" className={`${isMobile?"w-1/3":"w-1/4"}`}/>
        <div className="font-bold text-lg py-4">For best results:</div>
        <div className="font-bold text-lg ">Add more preferences</div>
        <div className="text-center">(such as types of investments, etc.)</div>
        <div>to best suit your needs</div>
        <div className={`flex items-end justify-between py-5  ${isMobile?"w-[80%]":"w-[70%]"}`}>
            <PrairiButton label="OK, let's customize" size="lg" border="thinGreen" rounded="soft"/>
            <div className={`py-4 ${isMobile&&"text-xs"}`}><TextButton color="silver" label="Skip for now"/></div>
        </div>
        </div>
    )
}

export default BestResults;