import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EyeIcon from "../assets/eyeicon.png";
import { investorContext, authContext } from "./context/context";
import SadLogo from "../assets/unicorn-crying.png";
import BalanceIcon from "../assets/balanceicon.png";
import PieIcon from "../assets/pieicon.png";
import { PrairiButton, Funding, BugTab } from "@peterfosso/prairi-components";
import TeleIcon from "../assets/teleicon.png";
import { navBarContext } from "./context/context";
import { updateLatestActions, sendBugEmails } from "../utils";
import { useMediaQuery } from "react-responsive";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import Terms from "./common/Terms";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  Timestamp,
} from "../firebase/firebaseApp";
const InvestorWelcome = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { setDataFunc, globalData } = useContext(investorContext);
  const { currentUser } = useContext(authContext);
  const time = Timestamp.fromDate(new Date());
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [renderedContent, setRenderedContent] = useState(0);
  const [goDataProfile, setGoDataProfile] = useState(false);
  const [country, setCountry] = useState("");
  const [showTerms, setShowTerms] = useState(false);
  const [docId, setDocId] = useState(null);
  const [userData, setUserData] = useState(null)
  const [please, setPlease] = useState(false);
  const [sorry, setSorry] = useState(false);
  const [agreeError, setAgreeError] = useState("")
  const [funding, setFunding] = useState(null);
    const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
    const [bugEmail, setBugEmail] = useState("");
    const [bugText, setBugText] = useState("");
      const [bugError, setBugError] = useState(null)

  const toggleTerms = () => {
    setShowTerms(!showTerms);
  };

  useEffect(() => {
    storeFirstView(docId);
    window.scrollTo(0, 0);
  }, []);

  const storeFirstView = async (userId) => {
    const userRef = doc(db, "users", userId);
    updateLatestActions(userRef, "First welcome slide view", isMobile);
  };

  const storeView = async (userId, slide) => {
    const userRef = doc(db, "users", userId);
    updateLatestActions(userRef, `view slide ${slide}`, isMobile);
  };

  const handleFunding = (event) => {
    const selectedValue = event.target.value === "true";
    setFunding(selectedValue);
    setAgreeError("")
};

  const handleUpload = (e) => {
    return new Promise((resolve, reject) => {
      const selectedFile = e.target.files[0];

      const storageRef = ref(storage, `bugs/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
        (error) => { // Manejador de error
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => { // Manejador de éxito
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
          console.log("uploaded correctly");
          setScreenshotUrl(downloadURL);
        }
      );
      
    });
  };

  const handleBugText = (e) => {
    const value = e.target.value;
    setBugText(value);
    if (value !== "") {
      setBugError("");
    }
  };
  
  const handleBugEmail = (e) => {
    const value = e.target.value;
    setBugEmail(value);
    if (value !== "") {
      setBugError("");
    }
  };
  
  const validateFields = () => {
    if (!bugText || bugText.trim() === "") {
      setBugError("Please describe the bug.");
      return false;
    }
    return true;
  };
  
  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;
  
      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUpload({ target: { files: [file] } });
      }
  
      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(
        userData.email,
        userData.contactName,
        bugText,
        "Investor welcome",
        uploadedUrl || ""
      );
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };


  const updateRenderedContent = () => {
    if (country === "United States") {
      if (renderedContent < 2) {
        setRenderedContent(renderedContent + 1);
        storeView(docId, renderedContent + 2);
      } else {
        // handleAgree();
        setGoDataProfile(true);
        setTimeout(() => {
          navigate("/investorregistration");
        }, 2000);
      }
    } else {
      if (renderedContent < 3) {
        setRenderedContent(renderedContent + 1);
        storeView(docId, renderedContent + 2);
      } else {
        setRenderedContent(3);
        setGoDataProfile(true);
        setTimeout(() => {
          navigate("/investorregistration");
        }, 2000);
      }
    }
  };

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
        setCountry(doc.data().country);
        setDocId(userId);

      });
      setUserData(userData)
      return { userData, userId };
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
  const fetchData = async () => {
    try {
      // Obtener datos del usuario
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          setCountry(data.country);
          setDocId(doc.id);
          setUserData(data);
        });
      } else {
        console.error("No se encontró al usuario en Firestore.");
      }
    } catch (error) {
      console.error("Error al obtener datos del usuario:", error);
    }
  };

  fetchData();
}, [currentUser]);


  const handleAgree = async (e) => {
    try {
      setAgreeError("")
      const { userData, userId } = await getDataFromDb();
      const userRef = doc(db, "users", userId);
      if (funding===null){
        setPlease(false)
        setAgreeError("Please make a selection (you can always change it later in your profile settings)")
        return;
      }
      await updateDoc(userRef, { agreed: true, startupPays: funding });
      updateLatestActions(userRef, `Agreed to terms ${funding?"(From startup share)":"From own funds"}`, isMobile);
      updateRenderedContent();
      // Document successfully updated
      //console.log("Document updated successfully.");
    } catch (error) {
      // Handle the error
      console.log("Error updating document:", error);
    }
  };

  const handleDisagree = async (e) => {
    try {
      const { userData, userId } = await getDataFromDb();
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, { agreed: false });
      updateLatestActions(userRef, "Disagreed to terms", isMobile);
      setPlease(true);
      // console.log("sorry :(")
    } catch (error) {
      console.log("error");
    }
  };

  const contentsNotUS = [
    {
      number: 1,
      image: EyeIcon,
      alt: "Eye Icon",
      content: (
        <>
          <p>
            As an Investor, you will now create your
            <span className="uppercase font-bold"> Data Profile.</span>
          </p>
          <br />
          <p>
            Data Profiles are hidden from the public and only viewable by
            startups after you've matched and you have selected 'Interested'.
          </p>
        </>
      ),
    },
    {
      number: 2,
      image: BalanceIcon,
      alt: "Balance Icon",
      content: (
        <p>
          Once your Data Profile is created, PRAIRI's proprietary algorithm will
          analyze and compare your criteria with current (and future) Startup
          profiles.
        </p>
      ),
    },
    {
      number: 3,
      image: TeleIcon,
      alt: "Tele Icon",
      content: (
        <>
          <p>
            Whenever there's a match (and you click 'Interested'), you can then
            contact the startup to arrange a meeting - which hopefully leads to
            a deal and your next unicorn.
          </p>
        </>
      ),
    },
    {
      number: 4,
      image: PieIcon,
      alt: "Pie Icon",
      content: (
        <>
          <p className="mb-5">
            I understand that PRAIRI is a startup and needs to be compensated to
            grow and thrive. If PRAIRI helps to find me a startup that I love
            (but that hasn’t already pitched to me within the last 6 months) and
            I invest in them, PRAIRI should be rewarded a small, 2% ‘success
            fee’.
          </p>
          <p>
            The fee, based on our total investment amount with the startup for
            this round of funding, shall be paid by us{" "}
            <span className="text-greenPrairi">(choose one)</span>:
          </p>
        </>
      ),
    },
  ];

  const contentsUS = [
    {
      number: 1,
      image: EyeIcon,
      alt: "Eye Icon",
      content: (
        <>
          <p>
            As an Investor, you will now create your
            <span className="uppercase font-bold"> Data Profile.</span>
          </p>
          <br />
          <p>
            Data Profiles are hidden from the public and only viewable by
            startups after you've matched and you have selected 'Interested'.
          </p>
        </>
      ),
    },
    {
      number: 2,
      image: BalanceIcon,
      alt: "Balance Icon",
      content: (
        <p>
          Once your Data Profile is created, PRAIRI's proprietary algorithm will
          analyze and compare your criteria with current (and future) Startup
          Profiles.
        </p>
      ),
    },
    {
      number: 3,
      image: TeleIcon,
      alt: "Tele Icon",
      content: (
        <>
          <p>
            Whenever there's a match (and you click 'interested') you can then
            contact the startup to arrange a meeting - which hopefully leads to
            a deal and your next unicorn.
          </p>

          {/* <p>Your first batch of matches is free. Then, if you like the quality of those matches, you can choose the version of PRAIRI you wish to use, free or paid.
          </p> */}
        </>
      ),
    },
  ];

  if (
    country === "United States" ||
    country === "United States of America (USA)"
  ) {
    return (
      <div
        className={` w-full md:max-w-2xl md:mx-auto  py-4 px-1 my-12`}
        style={{
          marginTop: navBarHeight + 8,
          // boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
          // borderRadius: "60px"
        }}
      >
                             {userData && (
                               <BugTab
                                 email={userData.email}
                                 name={userData.contactName}
                                 setEmail={handleBugEmail}
                                 setText={handleBugText}
                                 submit={(file) =>
                                   handleUploadAndSendEmail(file)
                                 }
                                 error={bugError}
                                 handleFileChange={handleUpload}
                               />
                             )}
        {renderedContent === 0 && (
          <h1 className="text-center text-3xl md:text-3xl mt-4 mb-8 font-bold text-textPrairi">
            Next step
          </h1>
        )}
        {/*<div className="text-3xl my-8 border-2 border-customWhite bg-body w-10 mx-auto rounded-full flex items-start justify-center">
        {contents[renderedContent].number}
        </div>*/}
        <div className="w-full flex flex-col items-center text-textPrairi text-lg md:text-md my-4">
          <div className="w-3/12 ">
            <img
              className="w-full mb-6"
              src={contentsUS[renderedContent].image}
              alt={contentsUS[renderedContent].alt}
            />
          </div>
          <div className=" mx-2 w-9/12">
            {contentsUS[renderedContent].content}
          </div>
        </div>
        <div className="text-center">
          {renderedContent < 2 && (
            <button
              onClick={updateRenderedContent}
              className="py-1.5 px-3 text-lg mt-3 mb-4 text-center rounded-lg bg-backgroundPrairi text-textPrairi border-2 border-greenPrairi"
            >
              Next
            </button>
          )}
          {renderedContent === 2 && (
            <div className="flex justify-center">
              <button
                onClick={updateRenderedContent}
                className="py-1.5 px-3 text-lg mt-3 mb-4  text-center rounded-lg bg-backgroundPrairi text-textPrairi border-2 border-greenPrairi"
              >
                OK, let's go!
              </button>
            </div>
          )}
        </div>
        {goDataProfile && (
          <div>
            <p className="mt-8 text-silverPrairi text-xl text-center ">
              Ok, it's time now to create your Data Profile...
            </p>
          </div>
        )}
        {showTerms && <Terms toggleTerms={toggleTerms} />}
      </div>
    );
  } else {
    return (
      <div
        className={` w-full md:max-w-2xl md:mx-auto  py-4 px-1 my-12`}
        style={{
          marginTop: navBarHeight + 8,
          // boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
          // borderRadius: "60px"
        }}
      >
                                     {userData && (
                               <BugTab
                                 email={userData.email}
                                 name={userData.contactName}
                                 setEmail={handleBugEmail}
                                 setText={handleBugText}
                                 submit={(file) =>
                                   handleUploadAndSendEmail(file)
                                 }
                                 error={bugError}
                                 handleFileChange={handleUpload}
                               />
                             )}
        {renderedContent === 0 && (
          <h1 className="text-center text-3xl md:text-3xl mt-4 mb-8 font-bold text-textPrairi">
            Welcome to PRAIRI
          </h1>
        )}
        {/*<div className="text-3xl my-8 border-2 border-customWhite bg-body w-10 mx-auto rounded-full flex items-start justify-center">
        {contents[renderedContent].number}
        </div>*/}
        <div className="w-full flex flex-col items-center text-textPrairi text-lg md:text-md my-4">
          <div className="w-3/12 ">
            <img
              className="w-full mb-6"
              src={contentsNotUS[renderedContent].image}
              alt={contentsNotUS[renderedContent].alt}
            />
          </div>
          <div className=" mx-2 w-9/12">
            {contentsNotUS[renderedContent].content}
          </div>
        </div>
        <div className="text-center">
          {renderedContent < 3 && (
            <button
              onClick={updateRenderedContent}
              className="py-1.5 px-3 text-lg mt-3 mb-4 text-center rounded-lg bg-backgroundPrairi text-textPrairi border-2 border-greenPrairi"
            >
              Next
            </button>
          )}
          {/*Last slide */}
          {renderedContent === 3 && (
            <div className="flex justify-center text-start  flex-col w-9/12 md:max-w-2xl mx-auto  ">
              <div className="flex flex-col ">
                <div className="flex mb-4 items-center ">
                  <input
                    type="radio"
                    id="false"
                    name="funding"
                    value={false}
                    onChange={handleFunding}
                    className="mr-3 w-5"
                  />
                  <label htmlFor={false}>
                    <p className="text-lg">
                      and <span className="underline">not</span> be taken from
                      the startup's share (
                      <span className="font-black text-greenPrairi text-2xl mx-1">
                        ·
                      </span>
                      )
                    </p>
                  </label>
                </div>

                <div className="flex mb-4 items-center " >
                  <input
                    type="radio"
                    id="funding"
                    name="funding"
                    value={true}
                    onChange={handleFunding}
                    className="mr-3 w-5"
                  />
                  <label htmlFor={false}>
                    <p className="text-lg">
                      allocated from the startup's share (
                      <span className="font-black text-yellowPrairi text-2xl mx-1">
                        ·
                      </span>
                      )
                    </p>
                  </label>
                </div>
                <div className="text-redPrairi mb-4">{agreeError}</div>
                {funding!==null && <p className="text-lg mb-5">Above as displayed to a startup (after you clicked 'Interested' on their profile):</p>}
                {funding!==null && <div className="flex justify-center"><div className="bg-lighterGray p-2 mb-4 rounded-lg w-[90%] flex justify-center items-center text-sm"><Funding startupPays={funding}/></div></div>}
              </div>
              <div className="flex justify-between  text-center lg:mx-[100px] mx-[20px] items-center">
                <PrairiButton
                  click={handleAgree}
                  border="green"
                  rounded="soft"
                  label="OK, I agree"
                  size="md"
                />
                <button onClick={handleDisagree}>
                  <p className="underline text-customWhite">no</p>
                </button>
              </div>
            </div>
          )}
        </div>
        {goDataProfile && (
          <div>
            <p className="mt-8 text-silverPrairi text-xl text-center ">
              Ok, it's time now to create your Data Profile...
            </p>
          </div>
        )}
        {showTerms && <Terms toggleTerms={toggleTerms} />}

        <Modal
          open={please}
          // onClose={() => setInterestedPopUp(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            className="min-w-[250px]
                max-w-[300px]"
            sx={{
              background: "#1e1e1d",
              color: "#f2f2f2",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: "35%",
              border: "2px solid #1e1e1d",
              borderRadius: "30px",
              padding: "2.5%",
              opacity: "1",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            }}
          >
            <p>
              Sorry, but we need you to agree so we can help find you your next
              great startup. Of course, if we fail to do so, there’s no fee,
              since receiving matches is FREE!
              <br />
              <br />
              Will you give us a chance to find you what you’re looking for? ‌ ‌
            </p>
            <div
              className="
                  flex 
                  justify-end 
                  items-center 
                  align-center
                  mt-4
                  w-1/2
                  mx-auto"
            >
              <button
                className="                    
               font-[sans-serif]   text-backgroundPrairi      mx-auto           tracking-wide    bg-backgroundPrairi text-textPrairi border-2 border-greenPrairi            hover:bg-gradient-to-t          
                 p-1 mr-2      border-backgroundPrairi               rounded-md   w-100"
                onClick={(event) => {
                  handleAgree();
                }}
              >
                OK, I agree
              </button>
              <button
                className="font-[sans-serif] text-customWhite underline mx-auto rounded-full w-[4rem]"
                onClick={() => setSorry(true)}
              >
                <p>no</p>
              </button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={sorry}
          onClose={() => navigate("/")}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            className="min-w-[250px]
                max-w-[300px]"
            sx={{
              background: "#1e1e1d",
              color: "#f2f2f2",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: "35%",
              border: "2px solid #1e1e1d",
              borderRadius: "30px",
              padding: "2.5%",
              opacity: "1",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            }}
          >
            <img src={SadLogo} className="w-1/5 h-auto mx-auto mb-4"></img>
            <p className="mb-4">
              OK, sorry to hear that. Well, we’ll keep your account for 14 days,
              just in case you change your mind.
            </p>
            <p>
              Log in anytime during that period to continue registering where
              you left off. ‌
            </p>
            <div
              className="
                  flex 
                  justify-end 
                  items-center 
                  align-center
                  mt-4
                  w-1/2
                  mx-auto"
            ></div>
          </Box>
        </Modal>
      </div>
    );
  }
};

export default InvestorWelcome;
