import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Unicorn from "../assets/prairiwhite.png";
import { navBarContext } from "./context/context";
import { sendNotMeEmail } from "../utils";

const NotMe = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { email } = useParams();

  useEffect(() => {
    const sendEmail = async () => {
      try {
        if (email) {
          await sendNotMeEmail(email);
        }
      } catch (error) {
        console.error("Error sending 'Not Me' email:", error);
      }
    };

    sendEmail();
  }, [email]); 
  return (
    <div className="w-full" style={{ marginTop: navBarHeight + 4 }}>
      <div className="flex flex-col justify-center text-[#f2f2f2] items-center mt-4">
        <h1 className="text-xl mb-4 font-bold">Thank you for letting us know!</h1>
        <img alt="logo" src={Unicorn} className="my-4 w-[70px]" />
        <div className="w-[90%] text-center">
          <p className="mb-5">
            We have recorded your response. If you received this email by mistake, don't worry—you don't need to do anything else.
          </p>
          <p>
            If you have any further concerns or need assistance, please{" "}
            <a href="https://www.prairi.com/partner" className="text-bluePrairi underline">
              contact us
            </a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotMe;
