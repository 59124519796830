import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { investorContext, authContext } from "./context/context";
import EyeIcon from "../assets/eyeicon.png";
import { getAnalytics, logEvent } from "firebase/analytics";
import Paper from "../assets/money_icon3.png";
import Money from "../assets/writeus.png";
import TeleIcon from "../assets/teleicon.png";
import WarningIcon from "../assets/warning_icon3.png";
import { updateLatestActions, sendBugEmails } from "../utils";
import Heart from "../assets/heart.png";
import { useMediaQuery } from "react-responsive";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import Handshake from "../assets/handshake.png";
import { navBarContext } from "./context/context";
import { analytics } from "../firebase/firebaseApp";
import { BugTab } from "@peterfosso/prairi-components";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  Timestamp,
} from "../firebase/firebaseApp";

const StartupWelcome = () => {
  const { navBarHeight } = useContext(navBarContext);
  let navigate = useNavigate();
  const [renderedContent, setRenderedContent] = useState(0);
  const [goDataProfile, setGoDataProfile] = useState(false);
  const { currentUser } = useContext(authContext);
  const [docId, setDocId] = useState(null);
  const [userData, setUserData] = useState(null);
  const time = Timestamp.fromDate(new Date());
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState("");
  const [bugError, setBugError] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const storeView = async (userId, slide) => {
    const userRef = doc(db, "users", userId);
    updateLatestActions(userRef, `view slide ${slide}`, isMobile);
  };

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      setDocId(userId);
      setUserData(userData);
      return { userData, userId };
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDataFromDb();
    };
    fetchData();
  }, [currentUser]);  // Dependencia de currentUser para evitar llamadas innecesarias
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUpload = (e) => {
    return new Promise((resolve, reject) => {
      const selectedFile = e.target.files[0];

      const storageRef = ref(storage, `bugs/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
        (error) => {
          // Manejador de error
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => {
          // Manejador de éxito
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
          console.log("uploaded correctly");
          setScreenshotUrl(downloadURL);
        }
      );
    });
  };

  const handleBugText = (e) => {
    const value = e.target.value;
    setBugText(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const handleBugEmail = (e) => {
    const value = e.target.value;
    setBugEmail(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const validateFields = () => {
    if (!bugText || bugText.trim() === "") {
      setBugError("Please describe the bug.");
      return false;
    }
    return true;
  };

  const handleAgree = () => {
    storeView(docId, "final");
    setGoDataProfile(true);
      navigate("/startupregistration");
  };

  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;

      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUpload({ target: { files: [file] } });
      }

      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(
        userData.email,
        userData.contactName,
        bugText,
        "Startup welcome",
        uploadedUrl || ""
      );
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };

  return (
    <div
      className={` w-full md:max-w-2xl md:mx-auto   py-4 px-1 my-12`}
      style={{
        marginTop: navBarHeight + 22,
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
        borderRadius: "15px",
      }}
    >
      {userData && (
        <BugTab
          email={userData.email}
          name={userData.contactName}
          setEmail={handleBugEmail}
          setText={handleBugText}
          submit={(file) => handleUploadAndSendEmail(file)}
          error={bugError}
          handleFileChange={handleUpload}
        />
      )}
      <h1 className="text-center text-3xl md:text-3xl mt-4 font-bold text-textPrairi">
        Last step!
      </h1>

              <div className="w-full text-textPrairi flex justify-center items-center flex-col text-lg md:text-md my-4">
        <div className=" flex-col flex w-full justify-center items-center mb-5">
          <div className=" my-10 flex w-auto gap-4 ">
          <img src={Money} alt="Eye icon" className="w-16" />
            <img src={Paper} alt="Balance Icon" className="w-16 " />

          </div>
          <div className="flex justify-center items-center w-[75%] mb-4 text-center">
            I hereby agree to pause my profile if/when we receive the investment
            we need
          </div>
        </div>
        <div className="flex justify-center items-center">
          <button
            onClick={() => {
              handleAgree();
            }}
            className="py-1.5 px-3 text-lg mt-3 mb-4 text-center rounded-lg bg-backgroundPrairi text-textPrairi border-2 border-greenPrairi"
          >
            I agree
          </button>
        </div>
      </div>
      


      <div className="text-center"></div>
      {goDataProfile && (
        <div>
          <p className="mt-8 text-silverPrairi text-xl text-center ">
            Ok, it's time now to create your Data Profile...
          </p>
        </div>
      )}
    </div>
  );
};

export default StartupWelcome;
