// import React, { useState, useEffect, useRef, useContext } from "react";
// import Logo from "../../assets/prairiwhite.png";
// import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
// import BreadcrumbStart from "../startup-breadcrumb/BreadcrumbStart";
// import BreadcrumbMiddle from "../startup-breadcrumb/BreadcrumbMiddle";
// import axios from "axios";
// import BreadcrumbLast from "../startup-breadcrumb/BreadcrumbLast";
// import CryingIcon from "../../assets/unicorn-crying.png";
// import { dataContext, navBarContext, authContext } from "../context/context";
// import _ from 'lodash';
// import {
//   query,
//   collection,
//   db,
//   where,
//   updateDoc,
//   doc,
//   getDocs,
// } from "../../firebase/firebaseApp";
// import { Modal, PrairiButton, SaveReminder } from "@peterfosso/prairi-components";

// const StartupRegistration = () => {
//   const { navBarHeight } = useContext(navBarContext);
//   const { currentUser } = useContext(authContext);
//   const didMount = useRef(false);
//   let navigate = useNavigate();
//   //Determine current URL, store in state and update current breadcrumb color
//   let location = useLocation();
//   const [errorPopup, setErrorPopup] = useState(false)
//   const [oldData, setOldData] = useState(null);
//   const [savePopup, setSavePopup] = useState(false);
//   const { globalData } = useContext(dataContext);
//   const companyData = globalData?.formData?.company;
//   const industryData = globalData?.formData?.industry;
//   const foundersData = globalData?.formData?.founders;
//   const ownershipData = globalData?.formData?.ownership;
//   const supportData = globalData?.formData?.support;

//   // This component will act as central place for all nested routes to update state
//   const [data, setData] = useState({
//     currentLocation: "",
//     formData: {
//       company: {
//         company: "",
//         url: "",
//         country: "",
//         state: "",
//         date: "",
//         slogan: "",
//         deck: "",
//         pitch: "",
//         completed: false,
//         investorButton: false,
//         generatedFields: {
//           profile: [{ fieldName: "field00", fieldValue: "" }],
//           resource: [{ fieldName: "field01", fieldValue: "" }],
//           investor: [{ fieldName: "field02", fieldValue: "" }],
//         },
//       },
//       industry: {
//         industry: "",
//         stage: "",
//         industry2: "",
//         currency: "EUR",
//         amount: 1000000,
//         model: "",
//         completed: false,
//       },
//       founders: {
//         founders: "0",

//         generatedFields: {
//           founder: [
//             {
//               fieldName1: "field03",
//               fieldValue1: "",
//               fieldName2: "field04",
//               fieldValue2: "",
//             },
//           ],
//           member: [
//             {
//               fieldName1: "field05",
//               fieldValue1: "",
//               fieldName2: "field06",
//               fieldValue2: "",
//             },
//           ],
//         },
//         nonfounder: "",

        
//           diversity: {
//             "woman": false,
//             "POC": false,
//             "LGBTQ+": false,
//             "immigrant": false,
//             "person with disabilities": false,
//             "senior/65+": false,
//             "teen/19-": false
//           }
        

//       },
//       ownership: {
//         capitalization: "",
//         traction: "",
//         expectedtraction: "",
//         revenue: 0,
//         completed: false,
//       },
//       support: {
//         support: false,
//         pgname: "",
//         hear: false,
//         expertise: "",
//         other: "",
//         remind: "",
//         completed: false,
//       },
//       matching: {
//         completed: false,
//       },
//     },
//   });
//   const getDataFromDb = async () => {
//     try {
//       // Get user data from email
//       const { email, uid } = currentUser;

//       const q = query(collection(db, "users"), where("email", "==", email));
//       const querySnapshot = await getDocs(q);

//     if (querySnapshot.empty) {
//       // No documents found, use currentUser.uid for further processing
//       return { userData: null, userId: uid };
//     }
//       let userData;
//       let userId;
//       querySnapshot.forEach((doc) => {
//         userData = doc.data();
//         userId = doc.id;
//       });
//       return { userData, userId };
//     } catch (error) {
//      console.log(error);
//     }
//   };


//   const hasUnsavedChanges = (oldData, newData) => {
//     return !_.isEqual(oldData, newData);
//   };

//   useEffect(() => {
//     const loadOldData = async () => {
//       const { userData } = await getDataFromDb();
//       if (!userData) {
//         console.error("No user data found!");
//         return;
//       }
//       setOldData(userData.formData); // Solo se establece una vez
//     };
  
//     loadOldData();
//   }, []); // Dependencia vacía para que solo se ejecute una vez al montar el componente
  
//   const handleCheckChanges = (breadcrumbPath) => {
//     if (!oldData) {
//       console.error("No old data loaded!");
//       return;
//     }
  
//     const hasChanges = hasUnsavedChanges(oldData.company, companyData) ||
//       hasUnsavedChanges(oldData.industry, industryData) ||
//       hasUnsavedChanges(oldData.founders, foundersData) ||
//       hasUnsavedChanges(oldData.ownership, ownershipData) ||
//       hasUnsavedChanges(oldData.support, supportData);
  
//     if (!hasChanges) {
//       navigate(breadcrumbPath);
//     } else {
//       console.log(`oldData: ${JSON.stringify(oldData)}`);
//       console.log(`data in forms: ${JSON.stringify(data)}`);
//       console.log(`hasChanges: ${hasChanges}`);
//       setSavePopup(hasChanges);
//     }
//   };
  

//   const updateLocalData = async () => {
//     const { userData } = await getDataFromDb();
//     if (userData.category === "startup") {
//       setData({ ...data, ...userData, currentLocation: "company" });
//     } else {
//       navigate("/investorregistration");
//     }
//   };


//   const sendErrorEmail = async () => {
//     try {
// //console.log('send email' + JSON.stringify(currentUser))
//       const payload = {
//         sender: {
//           name: "PRAIRI",
//           email: "invite@prairi.com",
//         },
//         to: [
//           {
//             name: "Caro",
//             email: "caro@prairi.com"
//           },
//         ],
//         templateId: 45,
//         params: {
//           DOCUID: currentUser.uid,
//           FILE: "StartupRegistration.js"
//         },
//       };
//       await axios({
//         method: "POST",
//         url: "https://api.sendinblue.com/v3/smtp/email",
//         headers: {
//           Accept: "application/json",
//           "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
//           "Content-Type": "application/json",
//         },
//         data: JSON.stringify(payload),
//       });
//     } catch (error) {
//       // Handle error
//       console.log(error);
//     }
//   };

//   const fireNotification = async () =>{
//     sendErrorEmail()
//     navigate("/")
//   }

//   const sendDataToDb = async () => {
//     const { userId } = await getDataFromDb();
//     try {
//       await updateDoc(doc(db, "users", userId), { ...data });
//     } catch (error) {
//      console.log(error);
//      sendErrorEmail()
//      setErrorPopup(true)
//     }
//   };
//   useEffect(() => {
//     let currentLocation = location.pathname.split("/").pop();
//     setData({ ...data, currentLocation });
//   }, [location]);
//   useEffect(() => {
//     updateLocalData();
//   }, []);
//   useEffect(() => {
//     if (didMount.current) {
//       sendDataToDb();
//     } else {
//       didMount.current = true;
//     }
//   }, [data.formData]);
//   return (
//     <div className="w-full px-0.5 bg-rainbowBg "
//     style={{ paddingTop: navBarHeight }}>
//       <div className={` w-full bg-body pt-2`}>
//         <h1 className="my-2 md:my-6 text-center text-base md:text-xl text-gray-500 uppercase">
//           Startup Data Profile
//         </h1>
//         <dataContext.Provider
//           value={{
//             setDataFunc: setData,
//             globalData: data,
//           }}
//         >
//           <nav className=" flex justify-around items-center md:justify-center w-full px-2 ">
//             <Link to="company">
//               <BreadcrumbStart title="Company" displayTitle="Company" />
//             </Link>
//             <Link to="industry">
//               <BreadcrumbMiddle
//                 title="Industry"
//                 displayTitle="Industry, Stage & Investment"
//               />
//             </Link>
//             <div onClick={()=>handleCheckChanges("/founders")} className="cursor-pointer">
//               <BreadcrumbMiddle
//                 title="Founders"
//                 displayTitle="Founders & Team"
//               />
//             </div>
//             <Link to="ownership">
//               <BreadcrumbMiddle
//                 title="Ownership"
//                 displayTitle="Ownership & Traction"
//               />
//             </Link>
//             <Link to="support">
//               <BreadcrumbMiddle
//                 title="Support"
//                 displayTitle="External Support"
//               />
//             </Link>
//             <Link to="matching" style={{ marginLeft: 10 }}>
//               <BreadcrumbLast title="matching" displayTitle="  " />
//             </Link>
//           </nav>
//           <div>
//             <Outlet />
//           </div>
//         </dataContext.Provider>
//       </div>
//       <Modal open={errorPopup} border="silver">
//         <div className="flex justify-center flex-col">
//           <div className="flex justify-center mb-2"><img className="flex justify-center w-[15%]" src={CryingIcon}></img></div>
//           <div className="flex justify-center font-bold my-2">
//             We’re sorry, but this isn’t working.{" "}
//           </div>
//           <div className="flex  mb-2">
//             It’s not you it’s us… it’s our fast growth and frequent site
//             updates.{" "}
//           </div>
//           <div className="flex  mb-2">
//             Please try again later. Thank you for your patience!{" "}
//           </div>
//           <div className="flex justify-center"><PrairiButton border="green" label="OK" size="sm" rounded="soft" click={()=>navigate("/")}/></div>

//         </div>
//       </Modal>
//       <SaveReminder open={savePopup} back={()=>setSavePopup(false)} leave={()=>console.log("leave")}/>
//     </div>
//   );
// };
// export default StartupRegistration;


import React, { useState, useEffect, useRef, useContext } from "react";
import Logo from "../../assets/prairiwhite.png";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import BreadcrumbStart from "../startup-breadcrumb/BreadcrumbStart";
import BreadcrumbMiddle from "../startup-breadcrumb/BreadcrumbMiddle";
import { sendBugEmails } from "../../utils.js";
import axios from "axios";
import BreadcrumbLast from "../startup-breadcrumb/BreadcrumbLast";
import CryingIcon from "../../assets/unicorn-crying.png";
import { dataContext, navBarContext, authContext } from "../context/context";
import {
  query,
  collection,
  db,
  where,
  updateDoc,
  doc,
  getDocs,
} from "../../firebase/firebaseApp";
import { Modal, PrairiButton, BugTab } from "@peterfosso/prairi-components";
import { storage } from "../../firebase/firebaseApp";

const StartupRegistration = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const didMount = useRef(false);
  let navigate = useNavigate();
  //Determine current URL, store in state and update current breadcrumb color
  let location = useLocation();
  const [errorPopup, setErrorPopup] = useState(false)
  const [userData, setUserData] = useState(null)
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState("");
  const [bugError, setBugError] = useState(null)

  // This component will act as central place for all nested routes to update state
  const [data, setData] = useState({
    currentLocation: "",
    formData: {
      company: {
        company: "",
        url: "",
        country: "",
        state: "",
        date: "",
        slogan: "",
        deck: "",
        pitch: "",
        completed: false,
        investorButton: false,
        generatedFields: {
          profile: [{ fieldName: "field00", fieldValue: "" }],
          resource: [{ fieldName: "field01", fieldValue: "" }],
          investor: [{ fieldName: "field02", fieldValue: "" }],
        },
      },
      industry: {
        industry: "",
        stage: "",
        industry2: "",
        currency: "EUR",
        amount: 1000000,
        model: "",
        completed: false,
      },
      founders: {
        founders: "0",

        generatedFields: {
          founder: [
            {
              fieldName1: "field03",
              fieldValue1: "",
              fieldName2: "field04",
              fieldValue2: "",
            },
          ],
          member: [
            {
              fieldName1: "field05",
              fieldValue1: "",
              fieldName2: "field06",
              fieldValue2: "",
            },
          ],
        },
        nonfounder: "",

        
          diversity: {
            "woman": false,
            "POC": false,
            "LGBTQ+": false,
            "immigrant": false,
            "person with disabilities": false,
            "senior/65+": false,
            "teen/19-": false
          }
        

      },
      ownership: {
        capitalization: "",
        traction: "",
        expectedtraction: "",
        revenue: 0,
        completed: false,
      },
      support: {
        support: false,
        pgname: "",
        hear: false,
        expertise: "",
        other: "",
        remind: "",
        completed: false,
      },
      matching: {
        completed: false,
      },
    },
  });
  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const { email, uid } = currentUser;

      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      // No documents found, use currentUser.uid for further processing
      return { userData: null, userId: uid };
    }
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      setUserData(userData)
      return { userData, userId };
    } catch (error) {
     console.log(error);
    }
  };

  const handleUpload = (e) => {
      return new Promise((resolve, reject) => {
        const selectedFile = e.target.files[0];
  
        const storageRef = ref(storage, `bugs/${selectedFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, selectedFile);
  
        uploadTask.on(
          "state_changed",
          null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
          (error) => { // Manejador de error
            console.error("Upload failed:", error);
            reject(error);
          },
          async () => { // Manejador de éxito
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
            console.log("uploaded correctly");
            setScreenshotUrl(downloadURL);
          }
        );
        
      });
    };
  
    const handleBugText = (e) => {
      const value = e.target.value;
      setBugText(value);
      if (value !== "") {
        setBugError("");
      }
    };
    
    const handleBugEmail = (e) => {
      const value = e.target.value;
      setBugEmail(value);
      if (value !== "") {
        setBugError("");
      }
    };
    
    const validateFields = () => {
      if (!bugText || bugText.trim() === "") {
        setBugError("Please describe the bug.");
        return false;
      }
      return true;
    };
    
  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;
  
      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUpload({ target: { files: [file] } });
      }
  
      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(
        userData.email,
        userData.contactName,
        bugText,
        "Startup registration",
        uploadedUrl || ""
      );
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };


  const updateLocalData = async () => {
    const { userData } = await getDataFromDb();
    if (userData.category === "startup") {
      setData({ ...data, ...userData, currentLocation: "company" });
    } else {
      navigate("/investorregistration");
    }
  };

  const sendErrorEmail = async () => {
    try {
//console.log('send email' + JSON.stringify(currentUser))
      const payload = {
        sender: {
          name: "PRAIRI",
          email: "invite@prairi.com",
        },
        to: [
          {
            name: "Caro",
            email: "caro@prairi.com"
          },
        ],
        templateId: 45,
        params: {
          DOCUID: currentUser.uid,
          FILE: "StartupRegistration.js"
        },
      };
      await axios({
        method: "POST",
        url: "https://api.sendinblue.com/v3/smtp/email",
        headers: {
          Accept: "application/json",
          "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      });
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  const fireNotification = async () =>{
    sendErrorEmail()
    navigate("/")
  }

  const sendDataToDb = async () => {
    const { userId } = await getDataFromDb();
    try {
      await updateDoc(doc(db, "users", userId), { ...data });
    } catch (error) {
     console.log(error);
     sendErrorEmail()
     setErrorPopup(true)
    }
  };
  useEffect(() => {
    let currentLocation = location.pathname.split("/").pop();
    setData({ ...data, currentLocation });
  }, [location]);
  useEffect(() => {
    updateLocalData();
  }, []);
  useEffect(() => {
    if (didMount.current) {
      sendDataToDb();
    } else {
      didMount.current = true;
    }
  }, [data.formData]);
  return (
    <div className="w-full px-0.5 bg-rainbowBg "
    style={{ paddingTop: navBarHeight }}>
            {userData && (
              <BugTab
                email={userData.email}
                name={userData.contactName}
                setEmail={handleBugEmail}
                setText={handleBugText}
                submit={(file) =>
                  handleUploadAndSendEmail(file)
                }
                handleFileChange={handleUpload}
                error={bugError}
              />
            )}
      <div className={` w-full bg-body pt-2`}>
        <h1 className="my-2 md:my-6 text-center text-base md:text-xl text-gray-500 uppercase">
          Startup Data Profile
        </h1>
        <dataContext.Provider
          value={{
            setDataFunc: setData,
            globalData: data,
          }}
        >
          <nav className=" flex justify-around items-center md:justify-center w-full px-2 ">
            <Link to="company">
              <BreadcrumbStart title="Company" displayTitle="Company" />
            </Link>
            <Link to="industry">
              <BreadcrumbMiddle
                title="Industry"
                displayTitle="Industry, Stage & Investment"
              />
            </Link>
            <Link to="founders">
              <BreadcrumbMiddle
                title="Founders"
                displayTitle="Founders & Team"
              />
            </Link>
            <Link to="ownership">
              <BreadcrumbMiddle
                title="Ownership"
                displayTitle="Ownership & Traction"
              />
            </Link>
            <Link to="support">
              <BreadcrumbMiddle
                title="Support"
                displayTitle="External Support"
              />
            </Link>
            <Link to="matching" style={{ marginLeft: 10 }}>
              <BreadcrumbLast title="matching" displayTitle="  " />
            </Link>
          </nav>
          <div>
            <Outlet />
          </div>
        </dataContext.Provider>
      </div>
      <Modal open={errorPopup} border="silver">
        <div className="flex justify-center flex-col">
          <div className="flex justify-center mb-2"><img className="flex justify-center w-[15%]" src={CryingIcon}></img></div>
          <div className="flex justify-center font-bold my-2">
            We’re sorry, but this isn’t working.{" "}
          </div>
          <div className="flex  mb-2">
            It’s not you it’s us… it’s our fast growth and frequent site
            updates.{" "}
          </div>
          <div className="flex  mb-2">
            Please try again later. Thank you for your patience!{" "}
          </div>
          <div className="flex justify-center"><PrairiButton border="green" label="OK" size="sm" rounded="soft" click={()=>navigate("/")}/></div>

        </div>
      </Modal>
    </div>
  );
};
export default StartupRegistration;