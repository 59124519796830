import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { navBarContext } from "./context/context";
import BuildPhone from "../assets/build.png"
import { useMediaQuery } from "react-responsive";
import { updateButtonClickCount, sendBugEmails } from "../utils";
import Logo from "../assets/prairiwhite.png"
import {
    collection,
    db,
    addDoc,
    Timestamp
  } from "../firebase/firebaseApp";
import { Modal, PrairiButton, BugTab } from "@peterfosso/prairi-components";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";

const Build = () => {
  let navigate = useNavigate();
  const time = Timestamp.fromDate(new Date())
  const { navBarHeight } = useContext(navBarContext);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [data, setData] = useState({
    email: "",
    error: "",
    loading: false,
    contactName: "",
    message: "",
    createdAt: "",
    emailError: false,
    contactNameError: false,
  });

  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState(null);
    const [bugError, setBugError] = useState(null)


    const handleUpload = (e) => {
      return new Promise((resolve, reject) => {
        const selectedFile = e.target.files[0];
  
        const storageRef = ref(storage, `bugs/${selectedFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, selectedFile);
  
        uploadTask.on(
          "state_changed",
          null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
          (error) => { // Manejador de error
            console.error("Upload failed:", error);
            reject(error);
          },
          async () => { // Manejador de éxito
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
            console.log("uploaded correctly");
            setScreenshotUrl(downloadURL);
          }
        );
        
      });
    };
  
    const handleBugText = (e) => {
      const value = e.target.value;
      setBugText(value);
      if (value !== "") {
        setBugError("");
      }
    };
    
    const handleBugEmail = (e) => {
      const value = e.target.value;
      setBugEmail(value);
      if (value !== "") {
        setBugError("");
      }
    };
    
    const validateFields = () => {
      if (!bugText || bugText.trim() === "") {
        setBugError("Please describe the bug.");
        return false;
      }
      if (!bugEmail || bugEmail.trim() === "") {
        setBugError("We need your email address.");
        return false;
      }
      return true;
    };
    
  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;

      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUpload({ target: { files: [file] } });
      }

      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(bugEmail, "", bugText, "Build", uploadedUrl || "");
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const updateFieldValidity = (bool, fieldName) => {
    if (bool) {
      setData({ ...data, [`${fieldName}Error`]: true });
    } else {
      setData({ ...data, [`${fieldName}Error`]: false });
    }
  };
  const isValid = (fieldName) => {
    switch (fieldName) {
      case "email":
        updateFieldValidity(!validator.isEmail(email), fieldName);
        break;
      case "contactName":
        updateFieldValidity(validator.isEmpty(contactName), fieldName);
        break;
      default:
        break;
    }
  };


  const {
    email,
    createdAt,
    error,
    loading,
    message,
    contactName,
    emailError,
    contactNameError,
  } = data;

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    updateButtonClickCount("Join Build-A-Startup button")
    setData({
      ...data,
      error: "",
      loading: true,
      message: "Sending request...",
    });
    try {
      // check if email is already used
      if (!data.email) {
        throw new Error("Email address is required.");
      }
      const docRef = await addDoc(collection(db, "BuildAStartup"), {
        contactName: data.contactName,
        email: data.email,
        createdAt: time
      });
      //console.log("Document written with ID: ", docRef.id);

      setConfirmationPopup(true); // Show the confirmation popup

    } catch (err) {
      setData({
        ...data,
        error: err.message,
        loading: false,
        message: "",
      });
    }
  };
  


  return (
    <div className="w-full" style={{ marginTop: navBarHeight + 4 }}>
      <BugTab
                email={bugEmail}
                
                setEmail={handleBugEmail}
                setText={handleBugText}
                submit={(file) =>
                  handleUploadAndSendEmail(file)
                }
                error={bugError}
                handleFileChange={handleUpload}
              />
      <div className=" w-full  bg-white">
        <div className="w-full rounded-xl  mt-2 pt-2.5 lg:pl-[120px] flex justify-center my-4 ">
            <div className="w-full rounded-xl  mt-2 py-2.5 px-5 items-start my-4 ">
          <h1 className=" text-start text-3xl lg:text-5xl font-bold text-black">
            Find Your Co-Founder <br/> or Grow Your Team
          </h1>
          <h3 className="my-2 text-3xl lg:text-5xl font-extralight text-black">
            (all for free)
          </h3>
          <h5 className="text-black font-bold text-xl lg:text-2xl mt-[50px]">
            Get notified when we launch PRAIRI's <span className="italic">totally</span> free {!isMobile&&<br/>} service for startups
          </h5>
          <div className="flex lg:w-3/4 lg:mt-4 mt-2 lg:items-start justify-center">
          <form className=" py-0 w-full lg:items-start" onSubmit={(e) => handleSubmit(e)}>
            <div className="lg:flex lg:justify-between  items-start">
            <div className="mt-5 w-full mr-2">
              <input
                className={`${
                  contactNameError ? "border-red-500" : "border-silverPrairi"
                } mt-2.5 w-full rounded border-2 p-2.5 text-black appearance-none outline-none`}
                type="text"
                name="contactName"
                id="contactName"
                placeholder="Name"
                value={contactName}
                onChange={handleChange}
                onBlur={() => isValid("contactName")}
              ></input>
              {contactNameError && (
                <div className="w-full text-sm text-red-500 mt-2">
                  Contact name is required
                </div>
              )}
            </div>
            <div className="mt-5 w-full lg:ml-2">
              <input
                className={`${
                  emailError ? "border-red-500" : "border-silverPrairi"
                } mt-2.5 w-full rounded border-2 p-2.5 text-black appearance-none outline-none`}
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={handleChange}
                onBlur={() => isValid("email")}
              ></input>
              {emailError && (
                <div className="w-full text-sm text-red-500 mt-2">
                  Provide a valid email
                </div>
              )}
            </div>

            </div>
            <div className=" my-2.5 mx-0 text-center flex flex-col">
              <input
                type="submit"
                disabled={loading}
                className="  bg-[#3964D7] w-full my-2 cursor-pointer rounded border border-silverPrairi p-2.5 text-lg text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                value="Join Build-A-Startup (it's free)"
              ></input>
                          {error && <p className="my-2 text-center text-red-500">{error}</p>}
            {message && (
              <p className="my-2 text-center text-green-500">{message}</p>
            )}
            </div>
          </form>
          </div>
          </div>
          {!isMobile && <img src={BuildPhone} className=""></img>}
        </div>
        {isMobile && <img src={BuildPhone}></img>}
      </div>
      <Modal open={confirmationPopup}>
              <div className="flex flex-col p-2 justify-center">
              <img src={Logo} alt="Unicorn logo" className="w-1/6 h-auto mx-auto mb-2"></img>
              <p className="text-center">Thank you!</p>
              <p className="text-center">We'll notifiy you as soon as we launch :)</p>
              <div className="flex justify-center mt-2"> <PrairiButton rounded="soft" label="OK" click={()=>navigate("/")} size="sm" border="green"/></div>
             
              </div>
      </Modal>
    </div>
  );
};

export default Build;