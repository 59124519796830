import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackgroundImg from "../assets/ad-blank-wide.jpg";
import { navBarContext } from "./context/context";
import Phone from "../assets/matchphone.png";
import { updateLatestActions, useWindowDimensions, sendBugEmails } from "../utils";
import { Loading, BugTab } from "@peterfosso/prairi-components";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import { db, getDoc, doc, updateDoc } from "../firebase/firebaseApp";
import { useMediaQuery } from "react-responsive";
const Register = () => {
  const { navBarHeight } = useContext(navBarContext);
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 800 });
  let { docId } = useParams();

  const { width, height } = useWindowDimensions();
  const deviceWidth = parseFloat(width);
  const deviceHeight = parseFloat(height);
  const huge = deviceWidth>2800;
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState(null);
    const [bugError, setBugError] = useState(null)


  const [data, setData] = useState({
    contactName: "",
    category: "",
  });

  const [loading, setLoading] = useState(true);
  const storeView = async (userId) => {
    const userRef = doc(db, "preUsers", userId); //We'll concat or something. But we're storing latest actions before registering in preUsers as well.
    updateLatestActions(userRef, "Invitation view", isMobile);
    //await updateDoc(userRef, {accessedRegisterForm: true})
  };

   const handleUpload = (e) => {
        return new Promise((resolve, reject) => {
          const selectedFile = e.target.files[0];
    
          const storageRef = ref(storage, `bugs/${selectedFile.name}`);
          const uploadTask = uploadBytesResumable(storageRef, selectedFile);
    
          uploadTask.on(
            "state_changed",
            null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
            (error) => { // Manejador de error
              console.error("Upload failed:", error);
              reject(error);
            },
            async () => { // Manejador de éxito
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
              console.log("uploaded correctly");
              setScreenshotUrl(downloadURL);
            }
          );
          
        });
      };
    
      const handleBugText = (e) => {
        const value = e.target.value;
        setBugText(value);
        if (value !== "") {
          setBugError("");
        }
      };
      
      const handleBugEmail = (e) => {
        const value = e.target.value;
        setBugEmail(value);
        if (value !== "") {
          setBugError("");
        }
      };
      
      const validateFields = () => {
        if (!bugText || bugText.trim() === "") {
          setBugError("Please describe the bug.");
          return false;
        }
        if (!bugEmail || bugEmail.trim() === "") {
          setBugError("We need your email address.");
          return false;
        }
        return true;
      };
      
    const handleUploadAndSendEmail = async (file) => {
      try {
        if (!validateFields()) return;
  
        let uploadedUrl = screenshotUrl;
        if (file) {
          uploadedUrl = await handleUpload({ target: { files: [file] } });
        }
  
        console.log(`screenshotUrl ${uploadedUrl}`);
        sendBugEmails(bugEmail, "", bugText, "Register", uploadedUrl || "");
      } catch (error) {
        console.error("Error uploading file or sending email:", error);
      }
    };
  

  useEffect(() => {
    window.scrollTo(0, 0);
    storeView(docId);
  }, []);

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const docRef = doc(db, "preUsers", docId); //now fetches from preUsers so it can read
        const docData = await getDoc(docRef);
        if (docData.exists()) {
          setData(docData.data());
        } else {
          //console.log("No such document!");
        }
        setLoading(false);
      } catch (error) {
        //console.error("Error fetching document:", error);
        setLoading(false);
      }
    };

    fetchDocumentData();
  }, [docId]);

  useEffect(() => {
    if (!loading && data.category === "startup") {
      navigate(`/create/${docId}`);
    }
  }, [data, docId, loading, navigate]);

  const getId = async () => {
    // get code and email from docId params
    if (docId && isNaN(Number(docId))) {
      try {
        const docRef = doc(db, "preUsers", docId);

        const docSnap = await getDoc(docRef);
        const { username: name, email, category, registered } = docSnap.data();
        setData({
          ...data,
          email,
          category,
          contactName: name.split(" ")[0],
        });
      } catch (error) {
        setData({
          ...data,
        });
      }
    } else {
      setData({ ...data, code: docId, codeComplete: true });
    }
  };

  useEffect(() => {
    getId();
    //console.log(location);
  }, [docId]);

  const { username, contactName, category } = data;

  if (loading) {
    return (
      <div className="mt-[100px]">
        <Loading />
      </div>
    ); // Mostrar una pantalla de carga mientras se obtienen los datos
  }

  return (
    <div className={`w-full ${isMobile ? "" : "bg-white"}  justify-center flex`}>
            <BugTab
                      email={bugEmail}
                      
                      setEmail={handleBugEmail}
                      setText={handleBugText}
                      submit={(file) =>
                        handleUploadAndSendEmail(file)
                      }
                      error={bugError}
                      handleFileChange={handleUpload}
                    />
      {!isMobile && data && (
        <div
          className={`flex ${deviceWidth<800?"w-[90%]":(deviceWidth>3000?"w-[50%]":"w-[80%]")}  bg-white justify-between items-end`}
          style={{
            marginTop: navBarHeight ,
            minWidth: "366px",

          }}
        >
          
          <div className="">
            <img
              src={Phone}
              className={`mx-auto`}
              alt="phone"
              style={{
                maxWidth: `${(2 * deviceWidth) / 5}px`,
                maxHeight: `${deviceHeight * 0.8}px`,
                width: "100%",
                height: "auto",
              }}
            />
          </div>
          <div className="flex items-center h-full">
          <div
            className={` text-black max-w-[650px]   flex flex-col`}
          >
            <div className={`text-redPrairi  mt-5 font-bold ${deviceWidth>3000?"text-[65px]":"text-[40px]"}`}>
              Welcome, {contactName}!
            </div>
            <div className={` ${deviceWidth>3000?"text-[70px]":"text-[54px]"} font-bold m-0`}>Find great startups</div>
            <div className={`${deviceWidth>3000?"text-[50px]":"text-[40px]"} mb-5 -mt-2 whitespace-nowrap`}>
              (without even looking for them)
            </div>{" "}
            {/* Added subtitle */}
            <div className={`w-[80%] font-bold ${deviceWidth>3000?"text-[30px]":"text-[18px]"} whitespace-nowrap`}>
              PRAIRI delivers startups to you based on your specific criteria.
            </div>
            <ul className={`pl-12 list-disc mt-4  custom-marker ${deviceWidth>3000?"text-[25px]":"text-lg"}`}>
              <li className="mb-2 ">
                Save time (no searching) - and{" "}
                <span className="underline">no</span> subscription fee.
              </li>
              <li className="mb-2">
                Get notified whenever there's a new startup that perfectly
                matches what you're looking for - in your region or anywhere in
                the world.
              </li>
              <li>
                If you're not on PRAIRI, you're missing out on great startups!
              </li>
            </ul>
            <div className="flex justify-center">
            <button
              className={`bg-blue-500 text-white ${deviceWidth>3000?"w-[50%] h-16 text-2xl":"h-12 w-[30%]"} font-bold px-20  my-8 rounded`}
              onClick={() => navigate(`/create/${docId}`)}
            >
              NEXT
            </button>
            </div>
          </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="h-screen  ">
          <div
            className="bg-[#262626] -mb-2 text-[white] bg-cover  flex flex-col items-start bg-center   w-full "
            style={{
              marginTop: navBarHeight,

              width: "100%",
              minWidth: "366px",
              padding: "2%",
            }}
          >
            <div className="flex text-start flex-col w-[98%] mx-4">
              <div className={`text-redPrairi ${huge?"text-[40px]":"text-[25px]"} my-4 font-bold`}>
                Welcome, {contactName}!
              </div>
              <div className={`font-bold m-0 ${huge?"text-[80px]":"text-[30px]"}`}>
                Find great startups
              </div>
              <div className=" text-[25px] mb-8 -mt-2">
                (without even looking for them)
              </div>{" "}
              {/* Added subtitle */}
              <div className="w-[90%] mb-4 text-xl font-bold text-justify ">
                PRAIRI delivers startups to you based on your specific criteria.
              </div>
              <ul className="list-disc mt-4 text-lg w-[95%] pl-4 text-xl custom-marker">
                <li className="mb-4 ">
                  Save time (there's no searching) - and{" "}
                  <span className="underline">no</span> subscription fee
                </li>
                <li className="mb-4">
                  Get notified whenever there's a new startup that perfectly
                  matches what you're looking for - in your region or anywhere
                  in the world.
                </li>
                <li className="mb-4">
                  If you're not on PRAIRI, you're missing out on great startups!
                </li>
              </ul>
              <div className="flex justify-center items-center w-full">
                <button
                  className="bg-blue-500 text-white flex justify-center items-center font-bold py-2 w-1/2 px-8 my-8 h-[50px] mb-8 rounded"
                  onClick={() => navigate(`/create/${docId}`)}
                >
                  NEXT
                </button>
              </div>
            </div>
            <img src={Phone} className="h-[20%] "></img>
          </div>
        </div>
      )}
      <style>
        {`
  .custom-marker li::marker {
    color:  #3B82F6;
    font-size: 1.5em;
  }
`}
      </style>
    </div>
  );
};

export default Register;
