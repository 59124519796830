import { getDoc, updateDoc, Timestamp,} from "firebase/firestore";
import {  query, collection, db, where, getDocs, addDoc, doc } from "../firebase/firebaseApp";
import axios from "axios";
import { addToSummary, updateLatestActions } from "../utils";

export const removePendingMatch = async (userId, matchId, setMatches) => {
    try {
      // Fetch the current user's document
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      if (userDoc.exists()) {
        const pendingMatches = userDoc.data().pendingMatches;
        const updatedMatches = pendingMatches.filter(
          (match) => match !== matchId
        );
        // Update the user's document with the modified "pendingMatches" array
        await updateDoc(userRef, { pendingMatches: updatedMatches });
        setMatches(updatedMatches);
      } else {
        console.log("User document not found");
      }
      if (startupDoc.exists()) {
        const pendingMatches = startupDoc.data().pendingMatches;
        const updatedMatches = pendingMatches.filter(
          (investor) => investor !== userId
        );
        console.log("startupDoc exists");
        // Update the user's document with the modified "pendingMatches" array
        await updateDoc(startupRef, { pendingMatches: updatedMatches });
        setMatches(updatedMatches);
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // console.error("Error removing match:", error);
    }
  };

  export const addInterestedMatch = async (userId, matchId, userRef) => {
    try {
      const userDoc = await getDoc(userRef);
      if (userDoc) {
        let updatedMatches;
        const userInterestedMatches = userDoc.data().interestedMatches || [];
        const startupToAdd = {id: matchId, status: "unknown"};
        if (!userInterestedMatches.includes(matchId)) {
          updatedMatches = [...userInterestedMatches, startupToAdd];
        }
        await updateDoc(userRef, { interestedMatches: updatedMatches });
        // console.log(`Match ${matchId} added to interested`);
      }
    } catch (error) {
      //console.error("Error removing match:", error);
    }
  }; //


 export const addArchivedMatch = async (userId, matchId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      //Adding startup so we can track archived for them as well
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      if (userDoc) {
        let updatedMatches;
        let updatedStartupArchived;
        const userArchivedMatches = userDoc.data().archivedMatches || [];
        const startupArchivedMatches = startupDoc.data().archivedMatches || [];
        const investorToAdd = userId;
        const startupToAdd = matchId;
        if (!userArchivedMatches.includes(matchId)) {
          updatedMatches = [...userArchivedMatches, startupToAdd];
        }
        if (!startupArchivedMatches.includes(userId)) {
          updatedStartupArchived = [...startupArchivedMatches, investorToAdd];
        }
        await updateDoc(userRef, { archivedMatches: updatedMatches });

        await updateDoc(startupRef, {
          archivedMatches: updatedStartupArchived,
        });
        // console.log(`Match ${matchId} added to archived`);
      }
    } catch (error) {
      // console.error("Error archivinf match:", error);
    }
  }; //


  export const addNotInterestedMatch = async (userId, matchId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      if (userDoc) {
        let updatedMatches;
        let updatedStartupNot;
        const userNotInterestedMatches =
          userDoc.data().notInterestedMatches || [];
        const startupNotInterestedMatches =
          startupDoc.data().notInterestedMatches || [];
        const startupToAdd = matchId;
        const investorToAdd = userId;
        if (!userNotInterestedMatches.includes(matchId)) {
          updatedMatches = [...userNotInterestedMatches, startupToAdd];
        }
        if (!startupNotInterestedMatches.includes(userId)) {
          updatedStartupNot = [...startupNotInterestedMatches, investorToAdd];
        }
        await updateDoc(userRef, { notInterestedMatches: updatedMatches });

        await updateDoc(startupRef, {
          notInterestedMatches: updatedStartupNot,
        });
        //console.log(`Match ${matchId} added to not interested`);
      }
    } catch (error) {
      // console.error("Error not interested match:", error);
    }
  };


  export const storeFeedback = async (matchId, userId, notInterestedReasons, textReason) => {
    try {
      let feedbackToAdd = notInterestedReasons;
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      let feedbackCount = userDoc.data().feedbacksGiven || 0;
      if (document.getElementById("other").checked && textReason) {
        feedbackToAdd.push(textReason);
      }

      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        const currentDate = new Date().toISOString();
        const data = {
          [currentDate]: feedbackToAdd,
        };

        let updatedFeedbacks;
        const startupInvestorFeedbacks =
          startupDoc.data().investorFeedback || [];
        updatedFeedbacks = [...startupInvestorFeedbacks, data];

        await updateDoc(startupDocRef, { investorFeedback: updatedFeedbacks });
        await updateDoc(userRef, { feedbacksGiven: feedbackCount + 1 });

        // console.log("Feedback stored for startup");
      } else {
        // console.log("No such document with matchId:", matchId);
      }
    } catch (error) {
      console.error("Error storing feedback", error);
    }
  };


  export const storeFlag = async (matchId, reason, actualDocId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);
      const userRef = doc(db, "users", actualDocId);
      if (startupDoc.exists()) {
        let updatedFlags;
        const startupFlags = startupDoc.data().flags || [];
        updatedFlags = [...startupFlags, reason];

        await updateDoc(startupDocRef, { flags: updatedFlags });

        // console.log("Flag stored for startup");
      } else {
        // console.log("No such document with matchId:", matchId);
      }
    } catch (error) {
      console.error("Error storing flag", error);
    }
  };


  export const storeInterestedInStartup = async (actualDocId, matchId) => {
    try {
      const userDocRef = doc(db, "users", actualDocId);
      const userDoc = await getDoc(userDocRef);

      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        let updatedInvestorInterests;
        const startupInvestorInterests =
          startupDoc.data().investorInterests || [];
        const investorInterestToAdd = userDoc.id;
        updatedInvestorInterests = [
          ...startupInvestorInterests,
          investorInterestToAdd,
        ];

        await updateDoc(startupDocRef, {
          investorInterests: updatedInvestorInterests,
        });
        // console.log(`Match ${matchId} received investor`);
      } else {
        // console.log("No such document with matchId:", matchId);
      }
    } catch (error) {
      console.error("Error storing investor interest", error);
    }
  };

  export const handleInterestedEmailNotification = async (actualDocId, matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);
      if (!startupDoc.data().emailConsent) {
        return;
      }
      if (startupDoc.exists()) {
        // Send an email to the startup about a new investor match
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "info@prairi.com",
          },
          to: [
            {
              name: startupDoc.data().contactName,
              email: startupDoc.data().email,
            },
          ],
          subject:
            "Congratulations! PRAIRI has found a new Investor match for you.",
          templateId: 33,
          params: {
            DOCID: startupDoc.id,
            EMAIL: startupDoc.data().email,
            INVID: actualDocId,
            NAME: startupDoc.data().contactName,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
      }
    } catch (err) {
      console.error("Error sending new match email to startup", err);
    }
  };


  export const handleInterestedSMSNotification = async (matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        if (!startupDoc.data().smsConsent) {
          return;
        }
        const messagesCollection = collection(db, "messages");
        const dataToStore = {
          to: `+${startupDoc.data().phone}`,
          body: "Congratulations! PRAIRI has found a new Investor who is interested in your startup! Check them out on your dashboard: https://prairi.com/dashboard",
        };
        await addDoc(messagesCollection, dataToStore);
      }
    } catch (error) {
      console.error("Error sending SMS notification to startup", error);
    }
  };


  export const handleFeedbackEmailNotification = async (matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        if (!startupDoc.data().emailConsent) {
          return;
        }
        // Send an email to the startup about feedback
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "info@prairi.com",
          },
          to: [
            {
              name: startupDoc.data().contactName,
              email: startupDoc.data().email,
            },
          ],
          subject: "We matched you with an investor...",
          templateId: 34,
          params: {
            EMAIL: startupDoc.data().email,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });

        // console.log(data);
        const lastNotification = new Date().toISOString();
        await updateDoc(startupDocRef, { lastNotification: lastNotification });
      }
    } catch (error) {
      console.error("Error sending feedback email to startup", error);
    }
  };


  export const handleFeedbackSMSNotification = async (matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        if (!startupDoc.data().smsConsent) {
          return;
        }
        const messagesCollection = collection(db, "messages");
        const dataToStore = {
          to: `+${startupDoc.data().phone}`,
          body: "PRAIRI matched you with an investor, but they were not interested. However, they left some feedback for you. Check it out on your dashboard: https://prairi.com/dashboard",
        };

        await addDoc(messagesCollection, dataToStore);
      }
    } catch (error) {
      console.error("Error sending feedback SMS to startup", error);
    }
  };


  export const fetchMatchDocId = async (startupId, investorId) => { //I should call all matchId startupId instead. Soon, I don't wanna break anything rn.
    const matchQuery = query(
      collection(db, "matches"),
      where("startup", "==", startupId),
      where("investor", "==", investorId)
    );
    const matchQuerySnapshot = await getDocs(matchQuery);
    
    if (matchQuerySnapshot.empty) {
      console.log("No match found.");
      return null; // O maneja el caso de no encontrar coincidencias
    }
    return matchQuerySnapshot.docs[0].id; 
  }


  export const handleMatchStatus = async (matchDocId, status) => { //this is not the onbe from the deal tracker!
    try{
        console.log(matchDocId) //Firebase
        const matchesCollection = collection(db, "matches");
        const matchDocRef = doc(matchesCollection, matchDocId)
        await updateDoc(matchDocRef, {
            status: status,
            [status + "Time"]: Timestamp.fromDate(new Date())
        })
    }
    catch(error){
        console.log(error)
    }
  }

  export const handleInterested = async (matchId, actualDocId, setMatches, userRef, setMatchesData, matchesData, isMobile, setInterestedPopUp) => {
    try {
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      const matchDocId = await fetchMatchDocId(matchId, actualDocId)
      storeInterestedInStartup(actualDocId, matchId);
      handleInterestedEmailNotification(actualDocId, matchId);
      handleInterestedSMSNotification(matchId);
      removePendingMatch(actualDocId, matchId, setMatches);
      addInterestedMatch(actualDocId, matchId, userRef);
      await handleMatchStatus(matchDocId, "Interested")
      setMatchesData(matchesData.slice(1));
      updateLatestActions(
        userRef,
        `Selected Interested in ${
          startupDoc.data().formData.company.company
        } (dashboard)`,
        isMobile
      );
      addToSummary(matchId, `Selected 'Interested'`, actualDocId);
      setInterestedPopUp(false);
    } catch (error) {
        console.error("Error in handleInterested:", error);
    }
  };


  export const handleArchived = async (matchId, actualDocId, setMatches, userRef, setMatchesData, matchesData, isMobile, setArchivedPopUp) => {
    try {
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      const matchDocId = await fetchMatchDocId(matchId, actualDocId)
      removePendingMatch(actualDocId, matchId, setMatches);
      addArchivedMatch(actualDocId, matchId);
      await handleMatchStatus(matchDocId, "Archived")
      setMatchesData(matchesData.slice(1));
      updateLatestActions(
        userRef,
        `Selected Archived in ${
          startupDoc.data().formData.company.company
        } (dashboard)`,
        isMobile
      );
      addToSummary(matchId, `Archived your profile for later viewing`, actualDocId);
      setArchivedPopUp(false);
    } catch (error) {
      // Handle the error
      // console.log("Error updating document:", error);
    }
  };

 export const handleNotInterested = async (matchId, actualDocId, notInterestedReasons, textReason, setMatches, setMatchesData, matchesData, userRef, isMobile, setNotInterestedPopUp) => {
    try {
      storeFeedback(matchId, actualDocId, notInterestedReasons, textReason);

      const currentDate = new Date().toISOString();
      const usersCollection = collection(db, "users");

      // Fetch the user document by matching on the "id" field
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);
      const matchDocId = await fetchMatchDocId(matchId, actualDocId)

      await handleMatchStatus(matchDocId, "NotInterested")
      if (startupDocSnap.exists()) {
        const startupData = startupDocSnap.data();

        const lastNotificationDate =
          (startupData.lastNotification || "").split("T")[0] ?? "";

        if (lastNotificationDate !== currentDate.split("T")[0]) {
          handleFeedbackEmailNotification(matchId);
          handleFeedbackSMSNotification(matchId);
        }
        removePendingMatch(actualDocId, matchId, setMatches);
        addNotInterestedMatch(actualDocId, matchId);
        setMatchesData(matchesData.slice(1));
        updateLatestActions(
          userRef,
          `Selected Not Interested in ${
            startupDocSnap.data().formData.company.company
          } and provided feedback (new match)`,
          isMobile
        );
        addToSummary(
          matchId,
          `Selected 'Not Interested' and provided feedback`,
          actualDocId
        );
        setNotInterestedPopUp(false);
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error handling not interested:", error);
    }
  };


  export const handleNotInterestedNoFeedback = async (matchId, actualDocId, setMatches, setMatchesData, matchesData, userRef, isMobile, setNotInterestedPopUp) => {
    try {
      const usersCollection = collection(db, "users");
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);
      const matchDocId = await fetchMatchDocId(matchId, actualDocId)
      await handleMatchStatus(matchDocId, "NotInterested")
      removePendingMatch(actualDocId, matchId, setMatches);
      addNotInterestedMatch(actualDocId, matchId);
      setMatchesData(matchesData.slice(1));
      updateLatestActions(
        userRef,
        `Selected Not Interested in ${
          startupDocSnap.data().formData.company.company
        } and chose not to provide feedback (new match)`,
        isMobile
      );
      addToSummary(
        matchId,
        `Selected 'Not Interested' and chose not to provide feedback`,
        actualDocId
      );
      setNotInterestedPopUp(false);
    } catch (error) {
      // Handle the error
      // console.log("Error updating document:", error);
    }
  };


  export const handleFlagging = async (matchId, reason, actualDocId, setMatches, setMatchesData, matchesData, userRef, isMobile, setFlagPopUp, setThankYouPopUp) => {
    try {
      storeFlag(matchId, reason, actualDocId);
      const usersCollection = collection(db, "users");

      // Fetch the user document by matching on the "id" field
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);
      const matchDocId = await fetchMatchDocId(matchId, actualDocId)

      await handleMatchStatus(matchDocId, "Flagged")

      if (startupDocSnap.exists()) {
        // Access the user data using startupDocSnap.data()
        const startupData = startupDocSnap.data();

        removePendingMatch(actualDocId, matchId, setMatches);
        addNotInterestedMatch(actualDocId, matchId);
        setMatchesData(matchesData.slice(1));

        // Your existing logic for sending email via Sendinblue
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "invite@prairi.com",
          },
          to: [
            {
              name: "Carolina",
              email: "kro.ruz@gmail.com",
            },
            {
              name: "Peter",
              email: "peter@prairi.com",
            },
          ],
          subject: "Flagged user",
          textContent: "This user has been flagged",
          templateId: 35,
          params: {
            INVESTOR: actualDocId,
            STARTUP: matchId,
            REASON: reason,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
        updateLatestActions(
          userRef,
          `Flagged ${startupData.formData.company.company}`,
          isMobile
        );
        setFlagPopUp(false);
        setThankYouPopUp(true);
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error handling flagging:", error);
    }
  };


  export const sendShareEmail = async (actualDocId, potentialId, potentialName, potentialEmail, userData) => {
    try {
      const usersCollection = collection(db, "users");
      const startupDocRef = doc(usersCollection, actualDocId);
      const startupDocSnap = await getDoc(startupDocRef);
      //console.log('send email' + JSON.stringify(currentUser))
      if (startupDocSnap.exists()) {
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "invite@prairi.com",
          },
          to: [
            {
              name: potentialName,
              email: potentialEmail,
            },
          ],
          templateId: 46,
          params: {
            STARTUPNAME: startupDocSnap.data().formData.company.company,
            INVESTORNAME: userData.contactName,
            STARTUPID: actualDocId,
            NAME: potentialName.split(" ")[0],
            POTENTIALID: potentialId,
          },
        };
        await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
      }
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };


 export const sendShareSMS = async (actualDocId, phone, id, potentialName, userData) => {
    try {
      const messagesCollection = collection(db, "messages");
      let processedPhone;
      phone.charAt(0) === "+"
        ? (processedPhone = phone)
        : (processedPhone = `+${phone}`);
      const dataToStore = {
        to: processedPhone,
        body: `Hi, ${potentialName.split(" ")[0]}. ${
          userData.contactName
        } has sent you a startup's profile on PRAIRI that you might be interested in. https://prairi.com/startup/${actualDocId}/${id}`,
      };
      await addDoc(messagesCollection, dataToStore);
    } catch (error) {
      console.log(error);
    }
  };


  export const handleShare = async (actualDocId, potentialPhone, potentialEmail, setShareError, potentialName, userRef, isMobile, setTySharePopup) => {
    const startupRef = doc(db, "users", actualDocId);
    const startupDoc = await getDoc(startupRef);
    if (potentialPhone === "" && potentialEmail === "") {
      console.log("missing data");
      setShareError("Please provide an email address or phone number");
      return;
    }
    let processedPhone;
    processedPhone =
      potentialPhone.charAt(0) === "+"
        ? (processedPhone = potentialPhone.replace("+", ""))
        : potentialPhone;
    const docRef = await addDoc(collection(db, "potential"), {
      username: potentialName,
      email: potentialEmail,
      phone: processedPhone,
      category: "investor",
      sharedBy: actualDocId,
      name: potentialName.split(" ")[0],
    });
    const id = docRef.id;
    sendShareEmail(actualDocId, id);
    sendShareSMS(actualDocId, potentialPhone, id);
    updateLatestActions(
      userRef,
      `Shared startup ${
        startupDoc.data().formData.company.company
      }with another investor`,
      isMobile
    );
    addToSummary(actualDocId, `Shared with another investor`, actualDocId);
    setTySharePopup(true);
    //console.log("sent?")
  };