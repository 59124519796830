import { borderColor } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import Approved from "../../assets/approved.png";
import How from "../../assets/howitworks.png";
import Case from "../../assets/ethic.png";
import Phone from "../../assets/mobilephone.png"
import TransitionUpwardElement from "./TransitionUpwardElement";
import { Bullet } from "@peterfosso/prairi-components";
import PrairiTextLogo from "../../assets/prairitext.png"
import Logo from "../../assets/prairiwhite.png";
import { useMediaQuery } from "react-responsive";

const SectionTwoLandingAd = (props) => {
  let navigate = useNavigate();
  const { show } = props;
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const workProcessSummary = [
    {
      step: 1,
      detail: "Apply in less than 2 minutes",
      image: How,
      alt: "How",
    },
    {
      step: 2,
      detail: "Receive confirmation* email in just 24-48 hours",
      image: Approved,
      alt: "Approved",
    },
    {
      step: 3,
      detail: "Complete your profile quick & easily to attract investors",
      image: Case,
      alt: "Case",
    },
    {
        step: 4,
        detail: "Sit back and let the right investors directly contact YOU",
        image: Phone,
        alt: "Phone",
      },
  ];

  return (
    <section
      className={`w-full  from-navStart to-navEnd text-customWhite ${
        show && "hidden"
      }`}
    >
    
      <TransitionUpwardElement
        elementType="p"
        elementClassNames="text-center animate-transitionIn py-4 bg-[#595959] text-2xl md:text-3xl mb-8  text-white"
      >
        <span className="font-bold">PRAIRI</span> is the private community where <span className="font-bold">Top Investors</span> are actively looking for startups
      </TransitionUpwardElement>
      <div className="w-full animate-transitionIn flex-col flex justify-center">
        <ul className="lg:pr-20 px-4 text-xl lg:text-2xl flex justify-center space-x-8 flex-col items-start md:flex-row md:justify-center md:space-x-8">
          {workProcessSummary.map((process, index) => (
            <li
              key={process.detail}
              className="flex flex-col items-center text-center"
            >
              <div
                className={`w-12 h-12 my-2 flex bg-body items-center justify-center text-white border-2 border-silverPrairi rounded-full`}
              >
                {process.step}
              </div>
              <p className="my-2">{process.detail}</p>
              {process.image && (
                <div className="w-full flex justify-center">
                  <img
                    className={`h-auto mt-4 justify-center text-center  ${process.step===4?"w-1/3":process.step===3?"w-1/2":"w-2/3"}`}
                    src={process.image}
                    alt={process.alt}
                  />
                </div>
              )}
            </li>
            
          ))}
        </ul>
        <p className="text-sm my-5">*if your application is declined you will be fully refunded</p>
      </div>
    </section>
  );
};

export default SectionTwoLandingAd;
