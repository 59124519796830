import React from "react";
import TransitionUpwardElement from "./TransitionUpwardElement";
import { useNavigate } from "react-router-dom";
const SectionThreeLandingAd = ({

}) => {
  let navigate = useNavigate();
  return (
    <section className="w-full bg-lastSection bg-cover  h-full  md:text-lg pb-52   lg:text-xl">
      <div className="w-full ">
        {" "}
        <TransitionUpwardElement
        elementType="p"
        elementClassNames="text-center w-full animate-transitionIn py-4 bg-[#595959] text-2xl md:text-3xl mb-8  text-white"
      >
         Imagine a world where investors reach out to <span className="font-bold">YOU</span>, instead of you chasing them.
      </TransitionUpwardElement>

        <TransitionUpwardElement
          elementType="h1"
          elementClassNames="text-3xl animate-transitionIn my-12  text-center tracking-widest md:text-5xl"
        >
          Is <span className="font-bold">PRAIRI</span>  worth it?
        </TransitionUpwardElement>{" "}
        <TransitionUpwardElement
          elementType="p"
          elementClassNames="my-12 animate-transitionIn text-3xl text-center"
        >
          Absolutely! For just $149, you gain the opportunity  to raise the capital you need and save on your time!
        </TransitionUpwardElement>{" "}
        <TransitionUpwardElement
          elementType="p"
          elementClassNames="my-12 animate-transitionIn text-3xl text-center"
        >

        </TransitionUpwardElement>{" "}
        <TransitionUpwardElement
          elementType="p"
          elementClassNames="my-12 pt-8 animate-transitionIn text-4xl text-center"
        >
          What a great <span className="font-bold">ROI</span>!
        </TransitionUpwardElement>{" "}

           <div

                  className={`flex items-center justify-center w-full  px-4  mb-10  text-white  lg:text-2xl translate-y-3/4 transition-all duration-1000 ease-in-out `}
                >
               <button className={`font-bold p-4 items-center justify-center rounded-full text-[#f2f2f2] bg-[#303030] border-2 solid  border-greenPrairi `} onClick={()=>navigate("/startuphome")}>
                Unlock Global Access to Investors 
                </button>
                </div>
        

      </div>
    </section>
  );
};

export default SectionThreeLandingAd;