import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import validator from "validator";
import { navBarContext } from "./context/context";
import { countryRegions } from "../countries.js";
import PhoneInput from "react-phone-input-2";
import { updateLatestActions, sendBugEmails } from "../utils";
import "react-phone-input-2/lib/style.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import { MdCheckCircle } from "react-icons/md";
import axios from "axios";
import Unicorn from "../assets/prairiwhite.png";
import { Modal, PrairiButton, BugTab } from "@peterfosso/prairi-components";
import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  deleteField,
  auth,
  Timestamp,
  updateDoc,
  addDoc,
  signInWithPhoneNumber,
} from "../firebase/firebaseApp";
import { useMediaQuery } from "react-responsive";
import JoinButton from "./common/JoinButton/JoinButton";
const StartupRegister = () => {
  const { navBarHeight } = useContext(navBarContext);
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  let { docId } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [verified, setVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [registeredPopup, setRegisteredPopup] = useState(false);
  const [SMSError, setSMSError] = useState("");
  const [region, setRegion] = useState("");
  const [generatedCode, setGeneratedCode] = useState("");
  const [verificationInProgress, setVerificationInProgress] = useState(false);
  const [location, setLocation] = useState({});
  const [smsPopup, setSmsPopup] = useState(false);
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState(null);
  const [bugError, setBugError] = useState(null);

  const [data, setData] = useState({
    email: "",
    error: "",
    loading: false,
    contactName: "",
    message: "",
    code: "",
    phone: "",
    country: "",
    state: "",
    smsConsent: true,
    emailConsent: true,
    contactMethod: "",
    password: "",
    codeError: false,
    codeComplete: false,
    smsError: false,
    emailError: false,
    emailComplete: false,
    contactNameError: false,
    contactNameComplete: false,
    countryError: false,
    countryComplete: false,
    stateError: false,
    stateComplete: false,
    phoneError: false,
    phoneComplete: false,
    passwordError: false,
    passwordComplete: false,
  });
  const handleUpload = (e) => {
    return new Promise((resolve, reject) => {
      const selectedFile = e.target.files[0];

      const storageRef = ref(storage, `bugs/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
        (error) => {
          // Manejador de error
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => {
          // Manejador de éxito
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
          console.log("uploaded correctly");
          setScreenshotUrl(downloadURL);
        }
      );
    });
  };

  const handleBugText = (e) => {
    const value = e.target.value;
    setBugText(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const handleBugEmail = (e) => {
    const value = e.target.value;
    setBugEmail(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const validateFields = () => {
    if (!bugText || bugText.trim() === "") {
      setBugError("Please describe the bug.");
      return false;
    }
    if (!bugEmail || bugEmail.trim() === "") {
      setBugError("We need your email address.");
      return false;
    }
    return true;
  };

  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;

      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUpload({ target: { files: [file] } });
      }

      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(
        bugEmail,
        "",
        bugText,
        "StartupRegister",
        uploadedUrl || ""
      );
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };

  const storeView = async (userId) => {
    const userRef = doc(db, "preUsers", userId); //We'll concat or something. But we're storing latest actions before registering in preUsers as well.
    updateLatestActions(userRef, "Register form view", isMobile);
    await updateDoc(userRef, { accessedRegisterForm: true });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    storeView(docId);
  }, []);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get(
          "https://ipinfo.io/json?token=00b36ccb7a5f35"
        );
        setLocation(response.data.country.toLowerCase());
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };

    fetchLocation();
  }, []); // Empty dependency array to ensure the effect runs only once on component mount

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const docRef = doc(db, "preUsers", docId); //now fetches from preUsers so it can read
        const docData = await getDoc(docRef);
        if (docData.exists()) {
          setData(docData.data());
        } else {
          //console.log("No such document!");
        }
      } catch (error) {
        //console.error("Error fetching document:", error);
      }
    };

    fetchDocumentData();
  }, [docId]);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const getId = async () => {
    // get code and email from docId params
    if (docId && isNaN(Number(docId))) {
      try {
        const docRef = doc(db, "preUsers", docId);

        const docSnap = await getDoc(docRef);
        const {
          username: name,
          email,
          category,
          registered,
          seeking,
        } = docSnap.data();
        if (registered) {
          setRegisteredPopup(true);
          return;
        } else {
          setData({
            ...data,
            email,
            seeking,
            category,
            emailComplete: email !== "",
            codeComplete: true,
            contactName: name,
            contactNameComplete: true,
            countryComplete: true,
          });
        }
      } catch (error) {
        setData({
          ...data,
        });
      }
    } else {
      setData({ ...data, code: docId, codeComplete: true });
    }
  };

  useEffect(() => {
    getId();
    //console.log(location);
  }, [docId]);

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setData({
        ...data,
        [e.target.name]: e.target.checked,
      });
    } else {
      setData({
        ...data,
        [e.target.name]:
          e.target.name === "code" ? Number(e.target.value) : e.target.value,
      });
    }
  };

  const updateFieldValidity = (bool, fieldName) => {
    if (bool) {
      setData({
        ...data,
        [`${fieldName}Error`]: true,
        [`${fieldName}Complete`]: false,
      });
      console.log(` error true, complete false${bool}  ${fieldName}`);
    } else {
      setData({
        ...data,
        [`${fieldName}Error`]: false,
        [`${fieldName}Complete`]: true,
      });
      console.log(` error false, complete true${bool}  ${fieldName}`);
    }
  };
  const isValid = (fieldName) => {
    switch (fieldName) {
      case "email":
        updateFieldValidity(!validator.isEmail(email), fieldName);
        break;
      case "contactName":
        updateFieldValidity(validator.isEmpty(contactName), fieldName);
        break;
      case "phone":
        updateFieldValidity(validator.isEmpty(phone), fieldName);
        break;
      case "password":
        updateFieldValidity(validator.isEmpty(password), fieldName);
        break;
      default:
        break;
    }
  };

  const isFinallyValid = () => {
    let dataCopy = { ...data };
    if (!smsConsent) {
      dataCopy = {
        ...dataCopy,
        smsError: true,
      };
    }
    if (country === "" && data.category === "investor") {
      dataCopy = {
        ...dataCopy,
        countryError: true,
        countryComplete: false,
        error: "Fill all the required fields",
      };
    }
    if (!validator.isEmail(email)) {
      dataCopy = {
        ...dataCopy,
        emailError: true,
        emailComplete: false,
        error: "Fill all the required fields",
      };
    }
    if (validator.isEmpty(contactName)) {
      dataCopy = {
        ...dataCopy,
        contactNameError: true,
        contactNameComplete: false,
        error: "Fill all the required fields",
      };
    }
    if (validator.isEmpty(phone)) {
      dataCopy = {
        ...dataCopy,
        phoneError: true,
        phoneComplete: false,
        error: "Fill all the required fields",
      };
    }
    if (validator.isEmpty(country) && data.category === "investor") {
      dataCopy = {
        ...dataCopy,
        countryError: true,
        countryComplete: false,
        error: "Fill all the required fields",
      };
    }
    // if (validator.isEmpty(state) && data.category==="investor" && country==="United States") {
    //   dataCopy = {
    //     ...dataCopy,
    //     stateError: true,
    //     stateComplete: false,
    //     error: "Fill all the required fields",
    //   };
    // }
    if (
      validator.isEmpty(password) ||
      password.length < 8 ||
      !/\d/.test(password) ||
      !/[!@#$%^&*(),.?":{}|<>]/.test(password)
    ) {
      dataCopy = {
        ...dataCopy,
        passwordError: true,
        passwordComplete: false,
        error:
          "Password must be at least 8 characters long and include at least one number and one special character",
      };
    }
    setData({ ...dataCopy });
    // override global variables
    const {
      codeError,
      emailError,
      contactNameError,
      phoneError,
      smsError,
      countryError,
      stateError,
      passwordError,
    } = dataCopy;
    return codeError ||
      emailError ||
      contactNameError ||
      phoneError ||
      passwordError ||
      countryError ||
      stateError ||
      smsError
      ? false
      : true;
  };

  const generateCode = () => {
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    const sixDigitCode = randomNumber.toString();
    return sixDigitCode;
  };

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };

  const sendCode = async (phone, SMSCode) => {
    const messagesCollection = collection(db, "messages");
    const dataToStore = {
      to: `+${phone}`,
      body: `PRAIRI: Your confirmation code is ${SMSCode}`,
    };

    await addDoc(messagesCollection, dataToStore);
  };

  const generateAndSendCode = (phone) => {
    const SMSCode = generateCode();
    //console.log("SMS Code:", SMSCode);
    setGeneratedCode(SMSCode);
    sendCode(phone, SMSCode);
  };

  const handleVerification = async () => {
    return new Promise((resolve, reject) => {
      try {
        if (generatedCode === verificationCode && !verificationInProgress) {
          //console.log("verified?" + JSON.stringify(verified));
          resolve(); // Resolve the promise when verification is successful
        } else {
          setSMSError("Wrong code, please try again.");
          reject(new Error("Verification failed")); // Reject the promise when verification fails
        }
      } catch (error) {
        console.error("Code verification error:", error);
        setSMSError("An error occurred while verifying the code.");
        reject(error); // Reject the promise on error
      }
    });
  };

  const handleUserVerification = async () => {
    try {
      await handleVerification();
      setVerified(true);
      setShowVerify(false);
      // Additional logic if needed after user triggers verification
    } catch (error) {
      // Handle errors if necessary
      console.error("User verification error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFinallyValid()) {
      return;
    }

    try {
      setData({
        ...data,
        error: "",
        loading: true,
        message: "Please wait...",
      });

      // Wait for the user to enter and submit the verification code
      if (isFinallyValid()) {
        try {
          // check if invitation code was provided to this email

          let userRef = doc(db, "preUsers", docId);
          let userDoc = await getDoc(userRef);
          let userData = userDoc.data();

          if (!userData.registered) {
            setData({
              ...data,
              error: "",
              loading: true,
              message: "Saving and proceeding to Step 2.",
            });
            const result = await createUserWithEmailAndPassword(
              auth,
              email,
              password
            );
            const emailLowerCase = email.toLowerCase();
            const time = Timestamp.fromDate(new Date());

            // Update the existing document in the 'users' collection
            let existingUserId = docId;

            if (existingUserId) {
              let existingUserRef = doc(db, "users", existingUserId);
              await updateDoc(existingUserRef, {
                uid: result.user.uid,
                contactName,
                phone,
                country,
                state,
                email: emailLowerCase,
                smsConsent,
                registeredAt: time,
                emailConsent,
                registered: true,
                paused: false,
              });
            } else {
              throw new Error("User document not found in 'users' collection.");
            }

            // Remove all fields except 'email' from preUsers document and set 'registered' to true
            await updateDoc(userRef, {
              email: emailLowerCase,
              registered: true,
              // category: deleteField(),
              // username: deleteField(),
              phone: deleteField(),
              state: deleteField(),
              smsConsent: deleteField(),
              createdAt: deleteField(),
              payCode: deleteField(),
              paymentPending: deleteField(),
              //seeking: deleteField(),
            });

            updateLatestActions(userRef, "Registered", isMobile);
            if (seeking === "NOT SEEKING") {
              navigate("/");
            } else {
              navigate(`/${userData.category}welcome`);
            }

            setData({
              ...data,
              loading: false,
            });
          } else {
            throw new Error("You are already registered. Sign in instead!");
          }
        } catch (err) {
          setData({
            ...data,
            error: err.message,
            loading: false,
            message: "",
          });
        }
      }
    } catch (err) {
      setData({
        ...data,
        error: err.message,
        loading: false,
        message: "",
      });
    }
  };

  const {
    email,
    error,
    message,
    code,
    contactName,
    phone,
    state,
    country,
    password,
    smsConsent,
    emailConsent,
    codeError,
    seeking,
    emailError,
    smsError,
    contactNameError,
    phoneError,
    stateError,
    stateComplete,
    passwordError,
    codeComplete,
    emailComplete,
    contactNameComplete,
    countryError,
    countryComplete,
    phoneComplete,
    passwordComplete,
  } = data;

  useEffect(() => {
    if (smsConsent) {
      setData({ ...data, smsError: false });
    }
  }, [smsConsent]);

  return (
    <section
      className={` shadow-black mx-auto w-full md:max-w-xl rounded-md py-2.5 px-5 shadow-md`}
      style={{ marginTop: navBarHeight + 4 }}
    >
      <BugTab
        email={bugEmail}
        setEmail={handleBugEmail}
        setText={handleBugText}
        submit={(file) => handleUploadAndSendEmail(file)}
        error={bugError}
        handleFileChange={handleUpload}
      />
      <h1 className="mb-4 text-center text-xl text-gray-500 uppercase">
        Welcome to PRAIRI
      </h1>
      <h3 className="mb-4  text-center text-base md:text-lg text-white">
        Create your account
      </h3>
      <form className=" py-0 px-5" onSubmit={handleSubmit}>
        <div className="mt-5 relative"></div>
        <div className="mt-5 relative">
          <label htmlFor="contactName">Contact Name</label>
          <div className="flex items-center">
            <input
              className={`${
                contactNameError ? "border-red-500" : "border-blackish"
              } mt-2.5 w-10/12 md:w-11/12 rounded border-2 p-2.5 text-black appearance-none outline-none`}
              type="text"
              name="contactName"
              id="contactName"
              placeholder="Your name"
              value={contactName}
              onChange={handleChange}
              onBlur={() => isValid("contactName")}
            ></input>
            {contactNameComplete && (
              <div className=" text-4xl ml-2 text-green-500 ">
                <MdCheckCircle />
              </div>
            )}
          </div>
          {contactNameError && (
            <div className="w-full text-sm text-red-500 mt-2">
              Contact name is required
            </div>
          )}
        </div>
        <div className="mt-5 relative">
          <label htmlFor="email">Email Address</label>
          <div className="flex items-center">
            <input
              className={`${
                emailError ? "border-red-500" : "border-blackish"
              } mt-2.5 w-10/12 md:w-11/12 rounded border-2 p-2.5 text-black appearance-none outline-none`}
              type="email"
              name="email"
              id="email"
              placeholder="mail@company.com"
              value={email}
              onChange={handleChange}
              onBlur={() => isValid("email")}
            ></input>
            {emailComplete && (
              <div className="ml-2 text-4xl  text-green-500 ">
                <MdCheckCircle />
              </div>
            )}
          </div>
          {emailError && (
            <div className="w-full text-sm text-red-500 mt-2">
              Provide a valid email
            </div>
          )}
        </div>
        {data.category === "investor" && (
          <div className="mt-5">
            <div>
              <label htmlFor="country">
                Location of investor/company (country where you are officially
                headquartered)
              </label>

              <select
                className="mt-2.5  border-blackish  md:w-11/12 rounded border-2 p-2.5 text-black  outline-none"
                value={region}
                onChange={handleRegionChange}
                name="region"
                id="region"
              >
                <option value="">Select a region</option>
                {Object.keys(countryRegions).map((region) => (
                  <option key={region} value={region}>
                    {region}
                  </option>
                ))}
              </select>

              {region && (
                <div className="flex items-center">
                  <select
                    className={`${
                      countryError ? "border-red-500" : "border-blackish"
                    } mt-2.5 w-10/12 md:w-11/12 rounded border-2 p-2.5 text-black outline-none`}
                    value={country}
                    onChange={handleChange}
                    name="country"
                    id="country"
                  >
                    <option value="">Select a country</option>
                    {countryRegions[region].map((option, index) => (
                      <option key={option.value}>{option}</option>
                    ))}
                  </select>

                  {country !== "" && (
                    <div className="text-4xl ml-2 text-green-500 ">
                      <MdCheckCircle />
                    </div>
                  )}
                </div>
              )}
              {/* {country === "United States" && (
              <div className="flex items-center">
                <select
                  className={`${
                    countryError ? "border-red-500" : "border-blackish"
                  } mt-2.5 w-10/12 md:w-11/12 rounded border-2 p-2.5 text-black outline-none`}
                  value={state}
                  onChange={handleChange}
                  name="state"
                  id="state"
                >
                  <option value="">Select a state</option>
                  {usStates.map((option, index) => (
                    <option key={option.value}>{option}</option>
                  ))}
                </select>
              </div>
            )} */}
            </div>
          </div>
        )}

        <div className="mt-5 relative">
          <label htmlFor="phone">Phone</label>
          <div className="flex items-center">
            <PhoneInput
              country={location}
              value={phone}
              onChange={(phone) => setData({ ...data, phone })}
              onBlur={() => isValid("phone")}
              inputProps={{
                id: "phone",
              }}
              countryCodeEditable={false}
              enableLongNumbers={true}
              inputStyle={{
                color: "black",
                borderColor: `${phoneError ? "red" : "#333"}`,
                marginTop: "0.625rem",
                width: "83%",
                borderRadius: "0.25rem",
                borderWidth: "1px",
                fontSize: "1rem",
                paddingTop: "0.625rem",
                paddingBottom: "0.625rem",
                paddingRight: "2rem",
                outline: "none",
              }}
              dropdownStyle={{ color: "black" }}
            />
            {phoneComplete && (
              <div className=" text-4xl ml-3 text-green-500 ">
                <MdCheckCircle />
              </div>
            )}
          </div>
          {phoneError && (
            <div className="w-full text-sm text-red-500 mt-2">
              Provide a valid phone number
            </div>
          )}
        </div>
        <div>
          <p className="text-sm mt-2 italic">
            PRAIRI sends notifications via SMS, so that you can immediately know
            when there’s a match. (You can always opt out later, in ‘Settings’,
            if you wish.)
          </p>
          <div className="flex">
            <div className="flex mt-[6px] items-center text-sm">
              <input
                className="mr-2"
                type="checkbox"
                id="agree"
                name="smsConsent"
                checked={smsConsent}
                onChange={handleChange}
              />
              <label for="no-traction"> I agree</label>
            </div>
            {smsError && (
              <div className="w-[200px] ml-2 border solid bg-black text-xs  p-0.5 rounded-md ">
                PRAIRI requires this. If you have unique circumstances{" "}
                <span
                  className="cursor-pointer underline text-silverPrairi"
                  onClick={() => navigate("/partner")}
                >
                  contact us
                </span>
                .
              </div>
            )}
          </div>
        </div>
        <div className="mt-5 relative">
          <label htmlFor="password">Password</label>
          <div
            className={`flex items-center justify-between ${
              passwordError ? "border-red-500" : "border-blackish"
            } bg-white mt-2.5 w-10/12 md:w-11/12 rounded border p-2.5 text-black `}
          >
            <input
              className=" outline-none w-10/12 md:w-11/12"
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder="At least 8 characters, one number and one special character"
              value={password}
              onChange={handleChange}
              onBlur={() => isValid("password")}
              autoComplete="on"
            ></input>
            <div className="text-2xl">
              {showPassword ? (
                <AiFillEye onClick={togglePassword} />
              ) : (
                <AiFillEyeInvisible onClick={togglePassword} />
              )}
            </div>
            {passwordComplete && (
              <div className="absolute text-4xl top-1/2 right-0 text-green-500 ">
                <MdCheckCircle />
              </div>
            )}
          </div>
        </div>
        <div className="text-xs italic">At least 8 characters, one number and one special character</div>

        {error && (
          <p className=" text-sm md:text-base my-2 text-red-500">{error}</p>
        )}
        {message && (
          <p className="text-sm md:text-base my-2  text-green-500">{message}</p>
        )}
        <div className=" my-4 mx-0 text-center">
          <JoinButton value="JOIN PRAIRI" />
        </div>
        <Modal open={registeredPopup} border="no-border">
          <div>
            <h1 className="mb-4 text-center text-xl text-gray-500 uppercase">
              Log in
            </h1>
            <p className="mb-5">Welcome Back</p>
            <p className="mb-5">We see you've already registered...</p>
            <p>- So just log in.</p>
            <p>- Complete your Data Profile</p>
            <p>- then start matching!</p>
            <div className="flex justify-center items-center mt-5">
              {" "}
              <PrairiButton
                label="OK"
                click={() => navigate("/login")}
                size="sm"
                border="green"
                rounded="soft"
                bgColor="regular"
              />
            </div>
          </div>
        </Modal>

        <Modal border="gray" open={showVerify}>
          <div className="p-3">
            <div className="v-header flex flex-col mb-3">
              <img src={Unicorn} className=" mb-2 w-1/5" />
              <p>Just one more thing!</p>
            </div>
            <p>
              Please confirm your phone number. We sent a verification code to{" "}
              {phone}
            </p>
            <div className="justify-center flex items-center ">
              <input
                placeholder="Your code"
                className="text-[#303030] my-3"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              ></input>
              <PrairiButton
                label="Verify"
                border="no-border"
                bgColor="regular"
                rounded="soft"
                size="sm"
                textColor="whiteText"
                click={handleUserVerification}
                type="button"
              />
            </div>
            <p className="v-verify-error">{SMSError}</p>
            <div className="v-message mt-2">
              Didn't get the SMS? Go back and double check your phone number. Or{" "}
              <span
                className="v-text-button"
                onClick={() => generateAndSendCode(phone)}
              >
                get a new code.
              </span>
            </div>
          </div>
        </Modal>

        <div id="recaptcha-container"></div>
      </form>
    </section>
  );
};

export default StartupRegister;
